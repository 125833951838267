import React from "react";
import { motion } from "framer-motion";

export default function AnimateItems(props) {
  const { children, delay } = props;
  return React.Children.map(children, (child, index) => {
    return (
      <motion.div
        initial={{
          opacity: 0,
          x: index % 2 === 0 ? -50 : 50,
        }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: index * delay }}
      >
        {child}
      </motion.div>
    );
  });
}
