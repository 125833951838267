import { Form, Input, Button, Modal, Space } from "antd";
import axios from "axios";

export default function TiposModForm({ visible, onCancel, setTiposModList }) {
  const [form] = Form.useForm();

  const onCloseTiposModForm = () => {
    form.resetFields();
    onCancel();
  };

  const onNewTipoMod = () => {
    form.validateFields().then((values) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/cat_tiposmod`, {
          TipoMod: form.getFieldValue("TipoMod"),
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/cat_tiposmod`).then((res) => {
            setTiposModList(res.data);
          });
          onCloseTiposModForm();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <Modal title="Agregar nuevo tipo de mod" visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" name="tipoModForm">
        <Form.Item label="Tipo" name="TipoMod" rules={[{ required: true, message: "Ingresa el tipo de mod" }]}>
          <Input />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 16,
          }}
        >
          <Space direction="horizontal">
            <Button type="primary" onClick={onNewTipoMod}>
              Agregar
            </Button>
            <Button type="primary" onClick={onCancel}>
              Cancelar
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
}
