import { Form, Input, Button, Modal, DatePicker, InputNumber, Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

export default function PaymentForm({ visible, onCancel, setModList, record }) {
  const [form] = Form.useForm();
  const [paymentsList, setPaymentsList] = useState();

  // get payments for selected mod
  useEffect(() => {
    if (record) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/pagos_mods/${record.ModID}`)
        .then((res) => {
          setPaymentsList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [record]);

  const handlePaymentCreate = () => {
    form.validateFields().then((values) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/pagos_mods`, {
          FechaPago: moment(form.getFieldValue("FechaPago")).format("YYYY-MM-DD"),
          MontoPago: form.getFieldValue("MontoPago"),
          Notas: form.getFieldValue("Notas"),
          ModID: record.ModID,
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/mods`).then((res) => {
            setModList(res.data);
          });
          onCancel();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const columns = [
    {
      title: "Fecha de pago",
      dataIndex: "FechaPago",
      key: "FechaPago",
      render: (text) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
    },
    {
      title: "Monto",
      dataIndex: "MontoPago",
      key: "MontoPago",
    },
    {
      title: "Notas",
      dataIndex: "Notas",
      key: "Notas",
    },
  ];

  return (
    <Modal title="Datos del pago" visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" name="paymentForm">
        <Form.Item label="Fecha de pago" name="FechaPago" rules={[{ required: true, message: "Ingresa la fecha del pago" }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item label="Monto" name="MontoPago" rules={[{ required: true, message: "Ingresa el monto del pago" }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Notas" name="Notas">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={handlePaymentCreate}>
            Agregar pago
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={paymentsList} columns={columns} />
    </Modal>
  );
}
