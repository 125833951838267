import { Row, Col, Card, Typography, DatePicker, Tag } from "antd";
import StatCard from "./StatCard";
import InspectorStats from "./InspectorStats";
import StatusPieChart from "./StatusPieChart";
import moment from "moment";
import { motion } from "framer-motion";

function DiagonalMotionDiv(props) {
  return (
    <motion.div animate={{ x: [-50, 0], y: [-250, 0] }} transition={{ delay: props.delay, duration: 0.35, ease: "easeInOut" }}>
      {props.children}
    </motion.div>
  );
}

export default function Stats() {
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Verificaciones",
      dataIndex: "verifications",
      key: "verifications",
      render: (verifications) => {
        if (verifications === 0) {
          return <Tag color="red">No tiene</Tag>;
        } else {
          return verifications;
        }
      },
    },
    {
      title: "Ingresos",
      dataIndex: "income",
      key: "income",
      responsive: ["lg"],
      render: (income) => {
        if (income === 0) {
          return <Tag color="red">No tiene</Tag>;
        } else {
          return income;
        }
      },
    },
    {
      title: "Monto promedio",
      dataIndex: "average",
      key: "average",
      responsive: ["lg"],
      render: (average) => {
        if (average === 0) {
          return <Tag color="red">No tiene</Tag>;
        } else {
          return average;
        }
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "Carlos Velasco",
      verifications: 10,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "2",
      name: "Juan Vázquez",
      verifications: 20,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "3",
      name: "Luis Ruiz",
      verifications: 5,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "4",
      name: "Eduardo Tarín",
      verifications: 3,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "5",
      name: "Fernando Muñoz",
      verifications: 2,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "6",
      name: "Jesús Montes",
      verifications: 2,
      income: "$860.2",
      average: "$4.6",
    },
    {
      key: "7",
      name: "José Rivas",
      verifications: 0,
      income: "$860.2",
      average: "$4.6",
    },
  ];

  const pieData = {
    labels: ["Pendientes", "Dictaminadas sin expediente", "Dictaminadas completas"],
    datasets: [
      {
        data: [20, 52, 28],
        backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
        borderWidth: 2,
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: window.innerWidth > 768 ? true : false,
        position: "top",
        align: "center",
        labels: {
          boxWidth: 50,
          padding: 10,
        },
        // title: {
        //   color: "rgba(18, 144, 255, 1)",
        //   display: true,
        //   font: {
        //     size: 16,
        //     weight: "bold",
        //   },
        //   padding: 10,
        //   text: "Estado de las verificaciones",
        // },
      },
    },
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Typography.Title level={3} className="fancy-titles" type="secondary">
            Estadisticas
          </Typography.Title>{" "}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <RangePicker
            defaultValue={[moment("2015/01/01", "DD/MM/YYYY"), moment("2015/01/01", "DD/MM/YYYY")]}
            format={"DD/MM/YYYY"}
            bordered={false}
          />
        </Col>
      </Row>
      <Row gutter={[60, 8]}>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.1}>
            <Card className="card-stats">
              <StatCard title="Cotizado" value="345600.21" prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.2}>
            <Card className="card-stats">
              <StatCard title="Dictaminado" value="242600.18" prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.3}>
            <Card className="card-stats">
              <StatCard title="Cobrado" value="186455.96" prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.4}>
            <Card className="card-stats">
              <StatCard title="Por cobrar" value="56144.22" prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
      </Row>
      <Row>
        <Typography.Title level={3} className="fancy-titles" type="secondary" style={{ paddingTop: "2vw" }}>
          Información adicional
        </Typography.Title>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <DiagonalMotionDiv delay={0.1}>
            <Card bordered={false} className="card-stats" title="Desempeño de los verificadores">
              <InspectorStats columns={columns} data={data} />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <DiagonalMotionDiv delay={0.3}>
            <Card bordered={false} className="card-stats" title="Estado de las verificaciones">
              <div
                style={{
                  maxWidth: "60%",
                  margin: "0 auto",
                  padding: "0 20px",
                }}
              >
                <StatusPieChart data={pieData} options={pieOptions} />
              </div>
            </Card>
          </DiagonalMotionDiv>
        </Col>
      </Row>
    </div>
  );
}
