import { Form, Input, Button, message, Modal } from "antd";
import { useEffect } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";

export default function NotasForm(props) {
  const { visible, title, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(verification);
  }, [verification, form]);

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.35}>
            <Form.Item name="Notas" label="Notas">
              <Input.TextArea rows={4} />
            </Form.Item>
          </AnimateItems>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            axios
              .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
              .then(() => {
                message.success("Notas actualizadas");
                setVerification(verificationData);
                setVisible(false);
              })
              .catch((err) => {
                message.error("Error al actualizar las notas");
              });
          }}
        >
          Guardar
        </Button>
      </Modal>
    </>
  );
}
