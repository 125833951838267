import { Form, DatePicker, Button, message, Modal } from "antd";
import { useEffect } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
moment.locale("es-mx");

export default function FechasForm(props) {
  const { title, visible, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      FechaCreacion: moment(verification?.FechaCreacion).isValid() ? moment(verification?.FechaCreacion) : null,
      FechaRecepcion: moment(verification?.FechaRecepcion).isValid() ? moment(verification?.FechaRecepcion) : null,
      FechaContrato: moment(verification?.FechaContrato).isValid() ? moment(verification?.FechaContrato) : null,
    });
  }, [verification, form]);

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.35}>
            <Form.Item name="FechaCreacion" label="Fecha de creación">
              <DatePicker
                disabled
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                disabledDate={(current) => current && current > moment().endOf("day")}
              />
            </Form.Item>
            <Form.Item name="FechaRecepcion" label="Fecha de la solicitud">
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                disabledDate={(current) => current && current > moment().endOf("day")}
              />
            </Form.Item>
            <Form.Item name="FechaContrato" label="Fecha del contrato">
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                disabledDate={(current) => current && current > moment().endOf("day")}
              />
            </Form.Item>
          </AnimateItems>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            axios
              .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
              .then(() => {
                message.success("Campos actualizados correctamente");
                setVerification(verificationData);
                setVisible(false);
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar los campos");
              });
          }}
        >
          Guardar
        </Button>
      </Modal>
    </>
  );
}
