import { Avatar, Menu, Dropdown, Space } from "antd";
import { UserOutlined, LogoutOutlined, DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function LoggedUser(props) {
  const { onLogout, username } = props;
  return (
    <div>
      <Avatar size="default" icon={<UserOutlined />} className="Avatar-circle" />
      <Dropdown
        overlay={
          <Menu>
            {/* <Menu.Item key="0">
              <Link to="/perfil">
                <Space>
                  <UserOutlined />
                  <span>Perfil de usuario</span>
                </Space>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="1" color="danger">
              <Link to="/" onClick={() => onLogout()}>
                <Space>
                  <LogoutOutlined />
                  <span>Cerrar sesión</span>
                </Space>
              </Link>
            </Menu.Item>
          </Menu>
        }
        trigger={["hover"]}
      >
        <Space>
          <span className="Avatar-text">
            {username.length > 10 ? username.substring(0, 10) + "..." : username}
            <DownOutlined />
          </span>
        </Space>
      </Dropdown>
    </div>
  );
}
