import { Form, Input, Select, Button, message } from "antd";
import { SaveOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { tipostelefono } from "../formOptions";
import axios from "axios";

export default function TelefonoForm(props) {
  const { type, telefono, personaEditing, setInfoTelefonos, setTelefonosFijos, setTelefonosMoviles } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(telefono);
  }, [form, telefono]);

  const onEditSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Editar", values);
      axios
        .put(`${process.env.REACT_APP_API_URL}/telefonos/${telefono?.TelefonoID}`, values)
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${personaEditing}`)
            .then((res) => {
              setInfoTelefonos(res.data);
              setTelefonosFijos(res.data.filter((telefono) => telefono.TipoTelefonoID === 2));
              setTelefonosMoviles(res.data.filter((telefono) => telefono.TipoTelefonoID === 1));
              message.success("Teléfono editado con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onAddSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Agregar", values);
      axios
        .post(`${process.env.REACT_APP_API_URL}/telefonos`, {
          ...values,
          PersonaID: personaEditing,
        })
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${personaEditing}`)
            .then((res) => {
              setInfoTelefonos(res.data);
              setTelefonosFijos(res.data.filter((telefono) => telefono.TipoTelefonoID === 2));
              setTelefonosMoviles(res.data.filter((telefono) => telefono.TipoTelefonoID === 1));
              message.success("Teléfono agregado con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onDeleteSubmit = (values) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/telefonos/${telefono?.TelefonoID}`)
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${personaEditing}`)
          .then((res) => {
            setInfoTelefonos(res.data);
            message.success("Teléfono eliminado con éxito");
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const clearForm = () => {
    form.resetFields();
  };

  return (
    <Form form={form}>
      <Form.Item key={telefono?.TelefonoID}>
        <Input.Group key={telefono?.TelefonoID} compact>
          <Form.Item
            name="TipoTelefonoID"
            label="Tipo de teléfono"
            noStyle
            rules={[{ required: true, message: "El tipo de teléfono es requerido" }]}
          >
            <Select style={{ width: "25%" }} placeholder="Tipo de teléfono">
              {tipostelefono.map((tipo) => (
                <Select.Option key={tipo.value} value={tipo.value}>
                  {tipo.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="Telefono" label="Teléfono" noStyle rules={[{ required: true, message: "El teléfono es requerido" }]}>
            <Input style={{ width: "60%" }} placeholder={type === "edit" ? "Teléfono" : "Agregar un teléfono"} />
          </Form.Item>
          <Button onClick={type === "edit" ? onEditSubmit : onAddSubmit} style={{ width: "8%" }}>
            <SaveOutlined style={{ color: "green" }} />
          </Button>
          <Button onClick={type === "edit" ? onDeleteSubmit : clearForm} style={{ width: "7%" }}>
            {type === "edit" ? <DeleteOutlined style={{ color: "red" }} /> : <CloseOutlined style={{ color: "red" }} />}
          </Button>
        </Input.Group>
      </Form.Item>
    </Form>
  );
}
