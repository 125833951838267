import { Layout, Affix } from "antd";
import { Outlet } from "react-router-dom";
import LogoArea from "./components/layout/LogoArea";
import SideMenu from "./components/layout/SideMenu";
import LoggedUser from "./components/layout/LoggedUser";
import Login from "./components/layout/Login";
import { useAuth } from "../src/components/layout/auth";
import { useNavigate } from "react-router-dom";
import "./App.css";

export default function App() {
  const { Header, Sider, Content } = Layout;
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate("/");
  };

  return !auth.authed ? (
    <Login />
  ) : (
    <Layout>
      <Affix offsetTop={0}>
        <Sider breakpoint="xl" collapsedWidth="0" className="App-sider">
          <LogoArea />
          <SideMenu />
        </Sider>
      </Affix>
      <Layout>
        <Affix offsetTop={0}>
          <Header className="App-header">
            <LoggedUser onLogout={handleLogout} username={auth.nombre} />
          </Header>
        </Affix>
        <Content className="App-content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
