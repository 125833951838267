import { Table } from "antd";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";

export default function VerificationsIndex(props) {
  const { verifications, loading, setSelectedVerification } = props;
  const columns = [
    {
      title: "Folio",
      dataIndex: "ControlInterno",
      key: "ControlInterno",
      width: 140,
      fixed: "left",
      render: (text, record) => {
        return (
          <Link to={`/verificaciones/${record.VerificacionID}`}>
            {record.FolioAmuvie ? (
              <>
                <CheckCircleFilled style={{ color: "#52c41a", marginRight: "10px" }} />
                {text}
              </>
            ) : (
              <>
                <WarningFilled style={{ color: "orange", marginRight: "10px" }} />
                {text}
              </>
            )}
          </Link>
        );
      },
    },
    {
      title: "Fecha",
      dataIndex: "FechaCreacion",
      key: "FechaCreacion",
      width: 120,
      responsive: ["md"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return moment.utc(text).format("DD/MM/YYYY");
        }
      },
    },
    {
      title: "Descripcion",
      dataIndex: "DescripcionBreve",
      key: "DescripcionBreve",
      width: 200,
      responsive: ["lg"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return <span className="break-by-word">{text}</span>;
        }
      },
    },
    {
      title: "Cliente",
      dataIndex: "NombreCliente",
      key: "NombreCliente",
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return <span className="break-by-word">{text}</span>;
        }
      },
    },
    {
      title: "Razón Social",
      dataIndex: "NombreCompleto",
      key: "NombreCompleto",
      responsive: ["lg"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return <span className="break-by-word">{text}</span>;
        }
      },
    },
    {
      title: "Tensión (V)",
      dataIndex: "TensionElectricaSuministro",
      key: "TensionElectricaSuministro",
      align: "right",
      width: 100,
      responsive: ["xxl"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
    },
    {
      title: "Carga (kW)",
      dataIndex: "CargaInstalada",
      key: "CargaInstalada",
      align: "right",
      width: 100,
      responsive: ["xxl"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return Number(text).toFixed(2);
        }
      },
    },
    {
      title: "Alcance (kW)",
      dataIndex: "AlcanceVerificacion",
      key: "AlcanceVerificacion",
      align: "right",
      width: 100,
      responsive: ["xxl"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return Number(text).toFixed(2);
        }
      },
    },
    {
      title: "Capacidad (kVA)",
      dataIndex: "Capacidad",
      key: "Capacidad",
      align: "right",
      width: 100,
      responsive: ["xxl"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return Number(text).toFixed(2);
        }
      },
    },
    {
      title: "Monto",
      dataIndex: "Monto",
      key: "Monto",
      align: "right",
      width: 110,
      fixed: "right",
      responsive: ["xxl"],
      render: (text, record) => {
        if (text === null) {
          return <span className="subtle-text">N/D</span>;
        } else {
          return (
            "$" +
            Number(text)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        }
      },
    },
  ];

  return (
    <div>
      <Table
        rowSelection={{
          type: "radio",
          onSelect: (record) => {
            setSelectedVerification(record.VerificacionID);
          },
        }}
        columns={columns}
        dataSource={verifications}
        loading={loading}
        rowKey="VerificacionID"
        pagination={{
          pageSize: 25,
          showSizeChanger: true,
          showLessItems: true,
        }}
        scroll={{
          x: "max-content",
          y: "calc(100vh - 300px)",
        }}
      />
    </div>
  );
}
