import { Form, Input, Select, Button, message, Modal, Divider, Space, Row, Col } from "antd";
import { CloseOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";
import PersonaForm from "../persona/PersonaForm";
import { scian } from "../formOptions";

export default function PVInfoForm(props) {
  const { title, visible, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [personaFormVisible, setPersonaFormVisible] = useState(false);
  const [datosPersona, setDatosPersona] = useState();
  const [telefonosFijos, setTelefonosFijos] = useState();
  const [telefonosMoviles, setTelefonosMoviles] = useState();
  const [correosPersona, setCorreosPersona] = useState();
  const [personasList, setPersonasList] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [personaEditing, setPersonaEditing] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    visible &&
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/personas`),
        axios.get(`${process.env.REACT_APP_API_URL}/datos/razonsocial/${verification?.PV}`),
        axios.get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${verification?.PV}`),
        axios.get(`${process.env.REACT_APP_API_URL}/correos/persona/${verification?.PV}`),
      ])
        .then(([p, d, t, c]) => {
          setPersonasList(p.data);
          setDatosPersona(d.data);
          setTelefonosFijos(t.data.filter((telefono) => telefono.TipoTelefonoID === 2));
          setTelefonosMoviles(t.data.filter((telefono) => telefono.TipoTelefonoID === 1));
          setCorreosPersona(c.data);
          form.setFieldsValue(verification);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
  }, [verification, form, visible]);

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  const colResposivity = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} centered>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.15}>
            <Row gutter={[40, 0]}>
              <Col {...colResposivity}>
                <Form.Item name="PV" label="Razón social">
                  <Select
                    onChange={(value) => {
                      if (value) {
                        Promise.all([
                          axios.get(`${process.env.REACT_APP_API_URL}/datos/razonsocial/${value}`),
                          axios.get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${value}`),
                          axios.get(`${process.env.REACT_APP_API_URL}/correos/persona/${value}`),
                        ])
                          .then(([d, t, c]) => {
                            setDatosPersona(d.data);
                            setTelefonosFijos(t.data.filter((telefono) => telefono.TipoTelefonoID === 2));
                            setTelefonosMoviles(t.data.filter((telefono) => telefono.TipoTelefonoID === 1));
                            setCorreosPersona(c.data);
                            form.setFieldsValue({
                              DatoPV: d.data[0]?.DatoID,
                              TelefonoFijoPV: t.data.filter((telefono) => telefono.TipoTelefonoID === 2)[0]?.TelefonoID,
                              TelefonoMovilPV: t.data.filter((telefono) => telefono.TipoTelefonoID === 1)[0]?.TelefonoID,
                              CorreoElectronicoPV: c.data[0]?.CorreoElectronicoID,
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                            message.error("Error al cargar los datos");
                          });
                      } else if (!value) {
                        setDatosPersona(null);
                        setTelefonosFijos(null);
                        setTelefonosMoviles(null);
                        setCorreosPersona(null);
                        form.setFieldsValue({
                          DatoPV: null,
                          TelefonoFijoPV: null,
                          TelefonoMovilPV: null,
                          CorreoElectronicoPV: null,
                        });
                      }
                      console.log(form.getFieldsValue());
                    }}
                    {...clearSelectProps}
                    open={openDropdown}
                    onDropdownVisibleChange={(visibility) => setOpenDropdown(visibility)}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <div style={{ float: "right" }}>
                          <Divider style={{ margin: "4px 0" }} />
                          <Space direction="horizontal">
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => {
                                setPersonaEditing(form.getFieldValue("PV"));
                                setOpenDropdown(false);
                                setTimeout(() => {
                                  setPersonaFormVisible(true);
                                }, 250);
                              }}
                            >
                              Editar persona
                            </Button>
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => {
                                setPersonaEditing(null);
                                setOpenDropdown(false);
                                setTimeout(() => {
                                  setPersonaFormVisible(true);
                                }, 250);
                              }}
                            >
                              Agregar persona
                            </Button>
                          </Space>
                        </div>
                      </>
                    )}
                  >
                    {personasList?.map((persona) => (
                      <Select.Option key={persona.PersonaID} value={persona.PersonaID}>
                        {persona.NombreCompleto}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="DatoPV" label="Forma de identificación de la Razón social">
                  <Select {...clearSelectProps}>
                    {datosPersona?.map((dato) => (
                      <Select.Option key={dato.DatoID} value={dato.DatoID}>
                        {dato.TipoDato_Corto + ": " + dato.Dato}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item name="TelefonoFijoPV" label="Teléfono fijo">
                      <Select {...clearSelectProps}>
                        {telefonosFijos?.map((telefono) => (
                          <Select.Option key={telefono.TelefonoID} value={telefono.TelefonoID}>
                            {telefono.Telefono}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="TelefonoMovilPV" label="Teléfono móvil">
                      <Select {...clearSelectProps}>
                        {telefonosMoviles?.map((telefono) => (
                          <Select.Option key={telefono.TelefonoID} value={telefono.TelefonoID}>
                            {telefono.Telefono}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="CorreoElectronicoPV" label="Correo electrónico">
                  <Select {...clearSelectProps}>
                    {correosPersona?.map((correo) => (
                      <Select.Option key={correo.CorreoElectronicoID} value={correo.CorreoElectronicoID}>
                        {correo.CorreoElectronico}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...colResposivity}>
                <Form.Item name="ClasificacionSCIANID" label="Clasificación SCIAN">
                  <Select {...clearSelectProps}>
                    {scian.map((tipo) => (
                      <Select.Option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="NombreComercial" label="Nombre comercial">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </AnimateItems>
        </Form>
        <PersonaForm
          setPersonasList={setPersonasList}
          setDatosPersona={setDatosPersona}
          setTelefonosFijos={setTelefonosFijos}
          setTelefonosMoviles={setTelefonosMoviles}
          setCorreosPersona={setCorreosPersona}
          personaEditing={personaEditing}
          personaFormVisible={personaFormVisible}
          setPersonaFormVisible={setPersonaFormVisible}
          onCancel={() => setPersonaFormVisible(false)}
        />
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            axios
              .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
              .then(() => {
                axios.get(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`).then((res) => {
                  setVerification(res.data[0]);
                  setVisible(false);
                });
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar la verificación");
              });
          }}
        >
          Guardar
        </Button>
        <Button
          onClick={() => {
            // lazy way to reset the page
            axios
              .get(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`)
              .then((res) => {
                setVerification(res.data[0]);
                setVisible(false);
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar la verificación\n" + err);
              });
          }}
        >
          Cancelar
        </Button>
      </Modal>
    </>
  );
}
