import { Row, Col, Typography, Button, Spin } from "antd";
import moment from "moment";
import { motion } from "framer-motion";

export default function DataItem(props) {
  const { icon, iconColor, keyName, value, valueType, prefix, suffix, fieldWidth, hidden, button, buttonFunction, loading } = props;

  if (value === null || value === undefined || value === "") {
    if (!hidden) {
      return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
          <Row className="data-row">
            <Col span={fieldWidth}>
              <span className="subtle-text">{keyName}</span>
            </Col>
            <Col span={24 - fieldWidth} style={{ textAlign: "right" }}>
              {loading ? <Spin size="small" /> : <span className="subtle-text">N/D</span>}
            </Col>
          </Row>
        </motion.div>
      );
    } else {
      return null;
    }
  }
  let valueFormatted = value;
  if (valueType === "date") {
    valueFormatted = moment.utc(value).format("DD/MM/YYYY");
  }
  if (valueType === "time") {
    valueFormatted = value.substring(0, 5);
  }
  if (valueType === "integer") {
    valueFormatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (valueType === "double") {
    valueFormatted = Number(value)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (valueType === "string") {
    valueFormatted = value;
  }
  if (valueType === "longString") {
    valueFormatted = value;
  }
  if (valueType === "phone") {
    valueFormatted = value.replace(/\D/g, "");
    valueFormatted = valueFormatted.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  if (valueType === "email") {
    valueFormatted = value.toLowerCase();
  }

  return valueType !== "longString" ? (
    <Row className="data-row">
      <Col span={fieldWidth} className="data-key">
        {icon && <span style={{ color: iconColor, marginRight: "5px" }}>{icon}</span>}
        <span>{keyName}</span>
      </Col>
      <Col span={24 - fieldWidth} className="data-value">
        {loading ? (
          <Spin size="small" />
        ) : (
          <>
            {prefix && <span style={{ marginRight: "5px" }}>{prefix}</span>}
            {valueFormatted}
            {suffix && <span style={{ marginLeft: "5px" }}>{suffix}</span>}
          </>
        )}
      </Col>
    </Row>
  ) : (
    <Row className="data-row">
      <Col span={24} className="data-key">
        <span>{keyName}</span>
      </Col>
      <Col span={24} className="data-value-longText">
        <Row gutter={[16, 16]}>
          <Col span={fieldWidth ? 24 - fieldWidth : 24}>
            {loading ? (
              <Spin size="small" />
            ) : (
              <>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: "Ver más",
                  }}
                >
                  {valueFormatted}
                </Typography.Paragraph>
              </>
            )}
          </Col>
          <Col span={fieldWidth ? fieldWidth : 0} style={{ textAlign: "right" }}>
            {button && (
              <Button type="primary" onClick={buttonFunction}>
                {button}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
