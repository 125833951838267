import { Form, Input, Select, Button, Row, Col, Space, Modal, message, Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import { tipospersona, nacionalidades } from "../formOptions";
import axios from "axios";
import DatoForm from "../persona/DatoForm";
import CorreoForm from "../persona/CorreoForm";
import TelefonoForm from "../persona/TelefonoForm";

export default function PersonaForm(props) {
  const {
    setPersonasList,
    setDatosPersona,
    setTelefonosFijos,
    setTelefonosMoviles,
    setCorreosPersona,
    personaEditing,
    personaFormVisible,
    setPersonaFormVisible,
    origin,
    setFoliosPersona,
    setDomiciliosPersona,
  } = props;
  const [form] = Form.useForm();
  const [infoDatos, setInfoDatos] = useState();
  const [infoTelefonos, setInfoTelefonos] = useState();
  const [infoCorreos, setInfoCorreos] = useState();
  const [isFisicaHidden, setIsFisicaHidden] = useState(true);
  const [isMoralHidden, setIsMoralHidden] = useState(true);
  const moralRef = useRef();
  const fisicaRef = useRef();

  useEffect(() => {
    if (personaEditing) {
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/personas/${personaEditing}`),
        axios.get(`${process.env.REACT_APP_API_URL}/datos/persona/${personaEditing}`),
        axios.get(`${process.env.REACT_APP_API_URL}/telefonos/persona/${personaEditing}`),
        axios.get(`${process.env.REACT_APP_API_URL}/correos/persona/${personaEditing}`),
      ])
        .then(([p, d, t, c]) => {
          form.setFieldsValue(p.data[0]);
          setInfoDatos(d.data);
          setInfoTelefonos(t.data);
          setInfoCorreos(c.data);
          if (p.data[0].TipoPersonaID === 1) {
            setIsFisicaHidden(false);
            setIsMoralHidden(true);
          } else if (p.data[0].TipoPersonaID === 2) {
            setIsMoralHidden(false);
            setIsFisicaHidden(true);
          } else {
            setIsFisicaHidden(true);
            setIsMoralHidden(true);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      form.resetFields();
      form.setFieldsValue({ NacionalidadID: 2 });
      setIsFisicaHidden(true);
      setIsMoralHidden(true);
    }
  }, [personaEditing, form, personaFormVisible]);

  useEffect(() => {
    if (isFisicaHidden) {
      moralRef.current?.focus();
      form.setFieldsValue({
        Nombre: null,
        ApellidoPaterno: null,
        ApellidoMaterno: null,
      });
    } else if (isMoralHidden) {
      fisicaRef.current?.focus();
      form.setFieldsValue({
        RazonSocial: null,
      });
    }
  }, [form, isFisicaHidden, isMoralHidden]);

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  const onEditSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .put(`${process.env.REACT_APP_API_URL}/personas/${personaEditing}`, values)
          .then((response) => {
            setPersonaFormVisible(false);
            message.success("Persona editada con éxito");
            axios
              .get(`${process.env.REACT_APP_API_URL}/personas`)
              .then((response) => {
                setPersonasList(response.data);
                message.success("Lista de personas actualizada con éxito");
              })
              .catch((error) => {
                console.log("Error gettting personas", error);
                message.error("Error actualizando lista de personas\n" + error.message);
              });
          })
          .catch((error) => {
            console.log("Request failed", error);
            message.error("Error editando persona\n" + error.message);
          });
      })
      .catch((error) => {
        console.log("Validation error", error);
        message.error("Por favor complete los campos requeridos\n" + error.message);
      });
  };

  const onAddSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/personas`, values)
          .then((response) => {
            message.success("Persona agregada con éxito");
            setPersonaFormVisible(false);
            axios
              .get(`${process.env.REACT_APP_API_URL}/personas`)
              .then((response) => {
                setPersonasList(response.data);
              })
              .catch((error) => {
                console.log("Error gettting personas", error);
                message.error("Error actualizando lista de personas\n" + error.message);
              });
          })
          .catch((error) => {
            console.log("Request failed", error);
            message.error("Error agregando persona\n" + error.message);
          });
      })
      .catch((error) => {
        console.log("Validation error", error);
        message.error("Por favor complete los campos requeridos\n" + error.message);
      });
  };

  const clearForm = () => {
    form.resetFields();
  };

  return (
    <>
      <Modal title="Persona" visible={personaFormVisible} onCancel={() => setPersonaFormVisible(false)} footer={null} centered width={700}>
        <Tabs>
          <Tabs.TabPane tab="General" key="1">
            <Form form={form} layout="vertical">
              <AnimateItems delay={0.15}>
                <Form.Item name="NacionalidadID" label="Nacionalidad" rules={[{ required: true, message: "Elige una nacionalidad" }]}>
                  <Select {...clearSelectProps}>
                    {nacionalidades.map((nacionalidad) => (
                      <Select.Option key={nacionalidad.value} value={nacionalidad.value}>
                        {nacionalidad.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="TipoPersonaID" label="Tipo de persona" rules={[{ required: true, message: "Elige el tipo de persona" }]}>
                  <Select
                    {...clearSelectProps}
                    onChange={(value) => {
                      setTimeout(() => {
                        if (value === 1) {
                          setIsFisicaHidden(false);
                          setIsMoralHidden(true);
                        } else if (value === 2) {
                          setIsMoralHidden(false);
                          setIsFisicaHidden(true);
                        } else {
                          setIsFisicaHidden(true);
                          setIsMoralHidden(true);
                        }
                      }, 350);
                    }}
                  >
                    {tipospersona.map((tipo) => (
                      <Select.Option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="Nombre" label="Nombre" rules={[{ type: "string", message: "Escribe el nombre" }]} hidden={isFisicaHidden}>
                  <Input ref={fisicaRef} />
                </Form.Item>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item
                      name="ApellidoPaterno"
                      label="Apellido paterno"
                      rules={[{ type: "string", message: "Escribe el apellido paterno" }]}
                      hidden={isFisicaHidden}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="ApellidoMaterno"
                      label="Apellido materno"
                      rules={[{ type: "string", message: "Escribe el apellido materno" }]}
                      hidden={isFisicaHidden}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="RazonSocial"
                  label="Razón social"
                  rules={[{ type: "string", message: "Escribe la razón social" }]}
                  hidden={isMoralHidden}
                >
                  <Input ref={moralRef} />
                </Form.Item>
              </AnimateItems>
            </Form>
            <div style={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    personaEditing ? onEditSubmit() : onAddSubmit();
                  }}
                >
                  {personaEditing ? "Actualizar" : "Guardar"}
                </Button>
                <Button
                  onClick={() => {
                    clearForm();
                    setPersonaFormVisible(false);
                  }}
                >
                  Cancelar
                </Button>
              </Space>
            </div>
          </Tabs.TabPane>
          {/* ----------------------------- DATOS ----------------------------- */}
          <Tabs.TabPane tab="Identificaciones" key="2">
            <Space direction="vertical" style={{ width: "100%" }}>
              {infoDatos?.map((dato) => (
                <DatoForm
                  key={dato.DatoID}
                  dato={dato}
                  personaEditing={personaEditing}
                  type="edit"
                  setInfoDatos={setInfoDatos}
                  setDatosPersona={setDatosPersona}
                  origin={origin}
                  setFoliosPersona={setFoliosPersona}
                  setDomiciliosPersona={setDomiciliosPersona}
                />
              ))}
              <DatoForm personaEditing={personaEditing} type="add" setInfoDatos={setInfoDatos} setDatosPersona={setDatosPersona} />
            </Space>
          </Tabs.TabPane>
          {/* ----------------------------- TELEFONOS ----------------------------- */}
          <Tabs.TabPane tab="Teléfonos" key="3">
            <Space direction="vertical" style={{ width: "100%" }}>
              {infoTelefonos?.map((telefono) => (
                <TelefonoForm
                  key={telefono.TelefonoID}
                  telefono={telefono}
                  personaEditing={personaEditing}
                  type="edit"
                  setInfoTelefonos={setInfoTelefonos}
                  setTelefonosFijos={setTelefonosFijos}
                  setTelefonosMoviles={setTelefonosMoviles}
                />
              ))}
              <TelefonoForm
                personaEditing={personaEditing}
                type="add"
                setInfoTelefonos={setInfoTelefonos}
                setTelefonosFijos={setTelefonosFijos}
                setTelefonosMoviles={setTelefonosMoviles}
              />
            </Space>
          </Tabs.TabPane>
          {/* ----------------------------- CORREOS ----------------------------- */}
          <Tabs.TabPane tab="Correos" key="4">
            <Space direction="vertical" style={{ width: "100%" }}>
              {infoCorreos?.map((correo) => (
                <CorreoForm
                  key={correo.CorreoID}
                  correo={correo}
                  personaEditing={personaEditing}
                  type="edit"
                  setInfoCorreos={setInfoCorreos}
                  setCorreosPersona={setCorreosPersona}
                />
              ))}
              <CorreoForm
                personaEditing={personaEditing}
                type="add"
                setInfoCorreos={setInfoCorreos}
                setCorreosPersona={setCorreosPersona}
              />
            </Space>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
}
