import { Form, Input, Select, Button, Space, message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import axios from "axios";

export default function AddVerificationForm(props) {
  const { setVerifications, visible, setVisible, setLoading } = props;
  const [form] = Form.useForm();
  const [clientes, setClientes] = useState();

  useEffect(() => {
    visible && form.resetFields();
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes`)
      .then((res) => {
        setClientes(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al cargar los clientes");
      });
  }, [visible, form]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_API_URL}/verificaciones`, {
            ...values,
            FechaCreacion: new Date(),
            UvieID: 1,
          })
          .then((res) => {
            setLoading(false);
            setVisible(false);
            message.success("Verificación agregada");
            axios
              .get(`${process.env.REACT_APP_API_URL}/verificaciones`)
              .then((res) => {
                setVerifications(res.data);
                message.success("Lista de verificaciones actualizada");
              })
              .catch((err) => {
                setLoading(false);
                console.log("Error al obtener las verificaciones", err);
                message.error("Error al obtener las verificaciones");
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error al agregar la verificación", err);
            message.error("Error al agregar la verificación");
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al validar los campos");
      });
  };

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  return (
    <>
      <Modal title="Crear verificación" visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical">
          <Form.Item name="DescripcionBreve" label="Descripción" rules={[{ required: true, message: "Escriba la descripción" }]}>
            <Input placeholder="Describa la verificación brevemente" />
          </Form.Item>
          <Form.Item name="ClienteID" label="Cliente" rules={[{ required: true, message: "Seleccione el cliente" }]}>
            <Select placeholder="Seleccione un cliente" {...clearSelectProps}>
              {clientes &&
                clientes.map((cliente) => (
                  <Select.Option key={cliente.ClienteID} value={cliente.ClienteID}>
                    {cliente.NombreCliente}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Agregar
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}
          >
            Cancelar
          </Button>
        </Space>
      </Modal>
    </>
  );
}
