import { Calendar, Tag, ConfigProvider, Spin } from "antd";
import es_ES from "antd/es/locale/es_ES";
import { EditFilled, BellFilled } from "@ant-design/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

export default function CalendarView() {
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState();
  const [dates, setDates] = useState();

  const onSelect = (value) => {
    setLoading(true);
    setSelectedValue(value);
  };

  const onPanelChange = (value) => {
    setLoading(true);
    setSelectedValue(value);
    setLoading(false);
  };

  const formatControlInterno = (value) => {
    if (value === null) {
      return "N/D";
    } else if (value.includes("/")) {
      const firstPart = value.split("/")[0];
      if (firstPart === "") {
        return value;
      } else {
        return firstPart;
      }
    } else {
      return value;
    }
  };

  useEffect(() => {
    Promise.all([
      axios.get(
        `${process.env.REACT_APP_API_URL}/verificaciones/calendario/visitas/
        ${moment(selectedValue).format("YYYY")}/
        ${moment(selectedValue).format("MM")}`
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/verificaciones/calendario/solicitud/
        ${moment(selectedValue).format("YYYY")}/
        ${moment(selectedValue).format("MM")}`
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/verificaciones/calendario/contrato/
        ${moment(selectedValue).format("YYYY")}/
        ${moment(selectedValue).format("MM")}`
      ),
    ])
      .then((response) => {
        const dates = response[0].data.map((date) => {
          return {
            id: date.id,
            ControlInterno: date.ControlInterno,
            Fecha: date.Fecha,
            Hora1: date.Hora1,
            Hora2: date.Hora2,
            Tipo: date.Tipo,
          };
        });
        response[1].data.forEach((date) => {
          dates.push({
            id: date.id,
            ControlInterno: date.ControlInterno,
            Fecha: date.Fecha,
            Hora1: null,
            Hora2: null,
            Tipo: date.Tipo,
          });
        });
        response[2].data.forEach((date) => {
          dates.push({
            id: date.id,
            ControlInterno: date.ControlInterno,
            Fecha: date.Fecha,
            Hora1: null,
            Hora2: null,
            Tipo: date.Tipo,
          });
        });
        dates.sort((a, b) => {
          return a.ControlInterno - b.ControlInterno;
        });
        setDates(dates);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedValue]);

  const dateCellRender = (value) => {
    const listData = dates?.filter((date) => moment(date.Fecha).format("YYYY-MM-DD") === value.format("YYYY-MM-DD"));
    return (
      <div>
        {listData?.map((item) => (
          <span key={listData.indexOf(item)}>
            {item.Tipo === "visita" ? (
              <span
                style={{
                  backgroundColor: "#f1f1f1",
                  color: "#001529",
                  padding: "0 5px",
                  marginBottom: "2px",
                  display: "block",
                  borderLeft: "4px solid #1890FF",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{formatControlInterno(item.ControlInterno)}</span>
                <span style={{ marginLeft: "5px" }}>
                  {item.Hora1 ? moment(item.Hora1, "HH:mm").format("HH:mm") : null}-
                  {item.Hora2 ? moment(item.Hora2, "HH:mm").format("HH:mm") : null}
                </span>
              </span>
            ) : item.Tipo === "solicitud" ? (
              <Tag color="DodgerBlue  ">
                <span style={{ marginLeft: "5px" }}>
                  <BellFilled />
                  {formatControlInterno(item.ControlInterno)}
                </span>
              </Tag>
            ) : item.Tipo === "contrato" ? (
              <Tag color="MediumPurple">
                <EditFilled />
                <span style={{ marginLeft: "5px" }}>{formatControlInterno(item.ControlInterno)}</span>
              </Tag>
            ) : null}
          </span>
        ))}
      </div>
    );
  };

  return (
    <ConfigProvider locale={es_ES}>
      <Spin
        spinning={loading}
        //center the spinner vertically and horizontally
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="calendar-container">
          <Calendar
            fullscreen={true}
            dateCellRender={dateCellRender}
            onSelect={onSelect}
            onPanelChange={onPanelChange}
            locale={{
              lang: {
                locale: "es_ES",
                dayFormat: moment.updateLocale("es_ES", {
                  weekdaysMin: ["Dom.", "Lun.", "Mar.", "Mié.", "Jue.", "Vie.", "Sáb."],
                }),
              },
            }}
          />
        </div>
      </Spin>
    </ConfigProvider>
  );
}