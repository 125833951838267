import { Form, Modal, Input, message, Button, AutoComplete, Typography } from "antd";
import { useEffect, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";

export default function PruebasForm(props) {
  const { title, mainData: visita, setMainData, visible, setVisible, verification } = props;
  const [contacto, setContacto] = useState("CONFORME");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ResResistenciaAislamiento: visita?.ResResistenciaAislamiento ? visita?.ResResistenciaAislamiento : "CONFORME",
      EqResistenciaAislamiento: visita?.EqResistenciaAislamiento
        ? visita?.EqResistenciaAislamiento
        : verification?.TipoVerificacionID === 1
        ? "NO APLICA, SE REVISA OCULARMENTE QUE ESTE LIBRE DE CORTOCIRCUITOS LOS CONDUCTORES PARA EL ALIMENTADOR GENERAL."
        : "NO APLICA, SE INSPECCIONA DE MANERA OCULAR QUE ESTE LIBRE DE CORTOCIRCUITOS Y CONEXIONES INCORRECTAS A TIERRA POR NO PODER DESENERGIZAR LA INSTALACION.",
      ResResistenciaTierraFisica: visita?.ResResistenciaTierraFisica ? visita?.ResResistenciaTierraFisica : "0.00 Ohms",
      EqResistenciaTierraFisica: visita?.EqResistenciaTierraFisica ? visita?.EqResistenciaTierraFisica : "UNI-T MOD. UT522",
      ResContinuidadElectrica: visita?.ResContinuidadElectrica ? visita?.ResContinuidadElectrica : "CONFORME",
      EqContinuidadElectrica: visita?.EqContinuidadElectrica ? visita?.EqContinuidadElectrica : "UNI-T MOD. UT502",
      ResPolaridadContactos: visita?.ResPolaridadContactos ? visita?.ResPolaridadContactos : "CONFORME",
      EqPolaridadContactos: visita?.EqPolaridadContactos ? visita?.EqPolaridadContactos : "NO APLICA, SE INSPECCIONA OCULARMENTE QUITANDO EL CONTACTO PARA VER QUE LOS CONDUCTORES LLEGUEN CORRECTAMENTE AL MISMO",
    });
  }, [visita, form, verification]);

  useEffect(() => {
    if (contacto === "CONFORME") {
      form.setFieldsValue({
        EqPolaridadContactos:
          "NO APLICA, SE INSPECCIONA OCULARMENTE QUITANDO EL CONTACTO PARA VER QUE LOS CONDUCTORES LLEGUEN CORRECTAMENTE AL MISMO",
      });
    } else {
      form.setFieldsValue({
        EqPolaridadContactos: "NO APLICA, NO CUENTA CON CONTACTOS",
      });
    }
  }, [contacto, form]);

  return (
    <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} centered>
      <Form form={form} layout="vertical">
        <AnimateItems delay={0.15}>
          <Typography.Title level={5}>1. Resistencia aislamiento</Typography.Title>
          <Form.Item name="ResResistenciaAislamiento" label="Resultado">
            <Input />
          </Form.Item>
          <Form.Item name="EqResistenciaAislamiento" label="Equipo">
            <Input.TextArea rows={1} />
          </Form.Item>
          <Typography.Title level={5}>2. Continuidad eléctrica de envolventes</Typography.Title>
          <Form.Item name="ResContinuidadElectrica" label="Resultado">
            <Input />
          </Form.Item>
          <Form.Item name="EqContinuidadElectrica" label="Equipo">
            <Input.TextArea rows={1} />
          </Form.Item>
          <Typography.Title level={5}>3. Resistencia de electrodos artificiales y de la red de tierras</Typography.Title>
          <Form.Item name="ResResistenciaTierraFisica" label="Resultado">
            <Input />
          </Form.Item>
          <Form.Item name="EqResistenciaTierraFisica" label="Equipo">
            <Input.TextArea rows={1} />
          </Form.Item>
          <Typography.Title level={5}>4. Polaridad de las conexiones en los receptáculos</Typography.Title>
          <Form.Item name="ResPolaridadContactos" label="Resultado">
            <AutoComplete options={[{ value: "CONFORME" }, { value: "NO APLICA" }]} onChange={(value) => setContacto(value)} />
          </Form.Item>
          <Form.Item name="EqPolaridadContactos" label="Equipo">
            <AutoComplete
              options={[
                {
                  value:
                    "NO APLICA, SE INSPECCIONA OCULARMENTE QUITANDO EL CONTACTO PARA VER QUE LOS CONDUCTORES LLEGUEN CORRECTAMENTE AL MISMO",
                },
                { value: "NO APLICA, NO CUENTA CON CONTACTOS" },
              ]}
            />
          </Form.Item>
        </AnimateItems>
      </Form>
      <Button
        type="primary"
        onClick={() => {
          const visitData = { ...visita, ...form.getFieldsValue() };
          axios
            .put(`${process.env.REACT_APP_API_URL}/visitas/${visita.VisitaID}`, visitData)
            .then(() => {
              message.success("Campos actualizados correctamente");
              setVisible(false);
              axios
                .get(`${process.env.REACT_APP_API_URL}/visitas/${visita.VerificacionID}`)
                .then((res) => {
                  message.success("Lista de visitas actualizada correctamente");
                  setMainData(res.data);
                })
                .catch((err) => {
                  console.log(err);
                  message.error("Error al actualizar la lista de visitas");
                });
            })
            .catch((err) => {
              console.log(err);
              message.error("Error al actualizar los campos");
            });
        }}
      >
        Guardar
      </Button>
    </Modal>
  );
}
