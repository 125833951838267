import { Form, DatePicker, TimePicker, Button, Space, message, Modal } from "antd";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
moment.locale("es-mx");

export default function AddVisitForm(props) {
  const { id, setVisits, visible, setVisible } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    visible && form.resetFields();
  }, [visible, form]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/visitas`, {
            VerificacionID: id,
            FechaVisita: moment.utc(values.FechaVisita).format("YYYY-MM-DD"),
            HoraInicio: moment(values.HoraInicio, "HH:mm:ss").format("HH:mm:ss"),
            HoraTermino: moment(values.HoraTermino, "HH:mm:ss").format("HH:mm:ss"),
          })
          .then((res) => {
            setVisible(false);
            message.success("Visita agregada");
            axios
              .get(`${process.env.REACT_APP_API_URL}/visitas/${id}`)
              .then((res) => {
                setVisits(res.data);
                message.success("Lista de visitas actualizada");
              })
              .catch((err) => {
                console.log("Error al obtener las visitas", err);
                message.error("Error al obtener las visitas");
              });
          })
          .catch((err) => {
            console.log("Error al agregar la visita", err);
            message.error("Error al agregar la visita");
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al validar los campos");
      });
  };

  return (
    <>
      <Modal title="Agregar Visita" visible={visible} onCancel={() => setVisible(false)} footer={null}>
        <Form form={form} layout="vertical">
          <Form.Item name="FechaVisita" label="Fecha de la visita" rules={[{ required: true, message: "Escriba la fecha de la visita" }]}>
            <DatePicker format={"DD/MM/YYYY"} style={{ width: "50%" }} disabledDate={(current) => current && current > moment().endOf("day")} />
          </Form.Item>
          <Form.Item name="HoraInicio" label="Hora de inicio" rules={[{ required: true, message: "Seleccione la hora" }]}>
            <TimePicker format="HH:mm" style={{ width: "50%" }} minuteStep={5} disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23] })} />
          </Form.Item>
          <Form.Item name="HoraTermino" label="Hora de Término" rules={[{ required: true, message: "Seleccione la hora" }]}>
            <TimePicker format="HH:mm" style={{ width: "50%" }} minuteStep={5} disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23] })} />
          </Form.Item>
        </Form>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Agregar
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}
          >
            Cancelar
          </Button>
        </Space>
      </Modal>
    </>
  );
}
