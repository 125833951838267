import { Typography } from "antd";

export default function Subtitle(props) {
  const { title, marginTop } = props;

  return (
    <Typography.Title level={5} type="secondary" style={{ marginTop: marginTop + "px" }}>
      {title}
    </Typography.Title>
  );
}
