import { Statistic } from "antd";

export default function StatCard(props) {
  return (
    <div>
      <Statistic
        title={props.title}
        value={props.value}
        valueStyle={{ 
          color: "#1890FF",
          fontSize: "calc(16px + 1vw)",
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
          }}
        prefix={props.prefix}
      />
    </div>
  );
}
