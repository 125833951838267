import { Form, Input, Select, Button, message } from "antd";
import { SaveOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { tiposdato } from "../formOptions";
import axios from "axios";

export default function DatoForm(props) {
  const { type, dato, personaEditing, setInfoDatos, setDatosPersona, origin, setFoliosPersona, setDomiciliosPersona } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(dato);
  }, [form, dato]);

  const onEditSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Editar", values);
      axios
        .put(`${process.env.REACT_APP_API_URL}/datos/${dato?.DatoID}`, values)
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/datos/persona/${personaEditing}`)
            .then((res) => {
              setInfoDatos(res.data);
              if (origin === "visita") {
                setFoliosPersona(res.data);
                setDomiciliosPersona(res.data);
              } else {
                setDatosPersona(res.data);
              }
              message.success("Identificación editada con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onAddSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Agregar", values);
      axios
        .post(`${process.env.REACT_APP_API_URL}/datos`, {
          ...values,
          PersonaID: personaEditing,
        })
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/datos/persona/${personaEditing}`)
            .then((res) => {
              setInfoDatos(res.data);
              setDatosPersona(res.data);
              message.success("Identificación agregada con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onDeleteSubmit = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/datos/${dato?.DatoID}`)
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/datos/persona/${personaEditing}`)
          .then((res) => {
            setInfoDatos(res.data);
            message.success("Identificación eliminada con éxito");
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const clearForm = () => {
    form.resetFields();
  };

  return (
    <Form form={form}>
      <Form.Item key={dato?.DatoID}>
        <Input.Group key={dato?.DatoID} compact>
          <Form.Item name="TipoDatoID" noStyle rules={[{ required: true, message: "Tipo de dato" }]}>
            <Select placeholder="Tipo de identificación" style={{ width: "25%" }}>
              {tiposdato.map((tipo) => (
                <Select.Option key={tipo.value} value={tipo.value}>
                  {tipo.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="Dato" label="Dato" noStyle rules={[{ required: true, message: "Por favor ingrese un dato" }]}>
            <Input key={dato?.DatoID} placeholder={type === "edit" ? "Dato" : "Agregar un dato nuevo"} style={{ width: "60%" }} />
          </Form.Item>
          <Button onClick={type === "edit" ? onEditSubmit : onAddSubmit} style={{ width: "8%" }}>
            <SaveOutlined style={{ color: "green" }} />
          </Button>
          <Button onClick={type === "edit" ? onDeleteSubmit : clearForm} style={{ width: "7%" }}>
            {type === "edit" ? <DeleteOutlined style={{ color: "red" }} /> : <CloseOutlined style={{ color: "red" }} />}
          </Button>
        </Input.Group>
      </Form.Item>
    </Form>
  );
}
