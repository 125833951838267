import { Table, Button, Space, Row, Col, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  //FilePdfFilled,
  FileWordFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import CotizacionesForm from "./forms/CotizacionesForm";
import moment from "moment";
import { useAuth } from "../../layout/auth";

export default function CotizacionesList() {
  const [cotizacionesList, setCotizacionesList] = useState();
  const [showCotizacionesModal, setShowCotizacionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [selectedCotizacion, setSelectedCotizacion] = useState();
  const [selectedRow, setSelectedRow] = useState();

  const auth = useAuth();

  const handleCotizacionFormCancel = () => {
    setShowCotizacionModal(false);
  };

  const handleCotizacionFormOpen = (record) => {
    setSelectedCotizacion(record);
    setShowCotizacionModal(true);
  };

  // const pdfStyle = {
  //   fontSize: "20px",
  //   color: "#AA0A00",
  // };

  const wordStyle = {
    fontSize: "20px",
    color: "#27508F",
  };

  const handleDeleteCotizacion = (record) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/cotizaciones/${record.CotizacionID}`)
      .then((res) => {
        axios.get(`${process.env.REACT_APP_API_URL}/cotizaciones`).then((res) => {
          setCotizacionesList(res.data);
        });
        setSelectedRow(null);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloneCotizacion = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/cotizaciones/clone/${selectedRow}`)
      .then((res) => {
        axios.get(`${process.env.REACT_APP_API_URL}/cotizaciones`).then((res) => {
          setCotizacionesList(res.data);
        });
        setSelectedRow(null);
        setShowCloneModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Solicitante",
      dataIndex: "Solicitante",
      key: "Solicitante",
    },
    {
      title: "Fecha de cotización",
      dataIndex: "FechaCotizacion",
      key: "FechaCotizacion",
      //render time ago and date
      render: (text, record) => (
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <span>{moment(record.FechaCotizacion).format("DD/MM/YYYY")}</span>
          </Col>
          <Col span={24}>
            <span>{moment(record.FechaCotizacion).fromNow()}</span>
          </Col>
        </Row>
      ),
    },
    {
      title: "Domicilio",
      render: (text, record) => (
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <span>
              {record.Calle} {record.NumeroExterior}
              {record.NumeroInterior ? `, ${record.NumeroInterior}` : ""}
              {record.Colonia ? `, Col. ${record.Colonia}` : ""}
            </span>
          </Col>
          <Col span={24}>
            <span>
              {record.Municipio}, {record.Estado}, {record.CodigoPostal}
            </span>
          </Col>
        </Row>
      ),
    },
    {
      title: "TipoTension",
      dataIndex: "TipoTension",
      key: "TipoTension",
    },
    {
      title: "CargaInstalada",
      dataIndex: "CargaInstalada",
      key: "CargaInstalada",
      align: "right",
    },
    {
      title: "PrecioFinal",
      dataIndex: "PrecioFinal",
      key: "PrecioFinal",
      align: "right",
      render: (text, record) => <span>{record.PrecioFinal.toLocaleString("es-MX", { style: "currency", currency: "MXN" })}</span>,
    },
    {
      title: "Acciones",
      dataIndex: "Acciones",
      key: "Acciones",
      render: (text, record) => (
        <Space size="small">
          <Button type="primary" icon={<EditOutlined />} onClick={() => handleCotizacionFormOpen(record)} />
          {/* <Button type="default" icon={<FilePdfFilled style={pdfStyle} />} onClick={() => handleCotizacionFormOpen(record)} /> */}
          <Button
            type="default"
            icon={<FileWordFilled style={wordStyle} />}
            onClick={() => {
              axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/formatos/cotizacion/${record.CotizacionID}`,
                responseType: "blob",
              })
                .then((response) => {
                  console.log(response.headers["content-type"]);
                  if (response.headers["content-type"] === "application/zip") {
                    const fileName = `cotizacion_${new Date().getTime()}.zip`;
                    const blob = new Blob([response.data], { type: "application/zip" });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                  } else if (
                    response.headers["content-type"] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  ) {
                    const fileName = `cotizacion_${new Date().getTime()}.docx`;
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setShowDeleteModal(true);
              setSelectedCotizacion(record);
            }}
            disabled={auth.rol !== "director"}
          />
        </Space>
      ),
    },
  ];

  //fetch from api/cotizaciones
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cotizaciones`)
      .then((res) => {
        setCotizacionesList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row gutter={[0, 16]} style={{ width: "100%" }}>
        <Col span={24} style={{ textAlign: "right" }}>
          <Space direction="horizontal">
            <Button type="primary" onClick={handleCotizacionFormOpen}>
              Agregar
            </Button>
            <Button type="default" onClick={() => setShowCloneModal(true)} disabled={!selectedRow}>
              Clonar
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => record.CotizacionID}
            columns={columns}
            dataSource={cotizacionesList}
            rowSelection={{
              type: "radio",
              onSelect: (record) => {
                setSelectedRow(record.CotizacionID);
              },
              selectedRowKeys: [selectedRow],
            }}
          />
        </Col>
      </Row>
      <CotizacionesForm
        visible={showCotizacionesModal}
        onCancel={handleCotizacionFormCancel}
        setCotizacionesList={setCotizacionesList}
        record={selectedCotizacion}
      />
      <Modal
        title="Eliminar cotización"
        visible={showDeleteModal}
        onOk={() => handleDeleteCotizacion(selectedCotizacion)}
        onCancel={() => setShowDeleteModal(false)}
      >
        <p>¿Está seguro de eliminar la cotización?</p>
      </Modal>
      <Modal
        title="Clonar cotización"
        visible={showCloneModal}
        onOk={() => handleCloneCotizacion(selectedCotizacion)}
        onCancel={() => setShowCloneModal(false)}
      >
        <p>¿Está seguro de clonar la cotización?</p>
      </Modal>
    </>
  );
}
