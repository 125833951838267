import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
// import Pending from "./components/views/goback/Pending";
import Page404 from "./components/views/goback/Page404";
import Stats from "./components/views/dashboard/Stats";
import Verifications from "./components/views/verifications/Verifications";
// import Inspectors from "./components/views/inspectors/Inspectors";
import Clients from "./components/views/clients/Clients";
import VerifiedPlaces from "./components/views/verified_places/VerifiedPlaces";
import Persons from "./components/views/persons/Persons";
import CalendarView from "./components/views/calendar/CalendarView";
import VerificationPreview from "./components/views/verifications/VerificationPreview";
import Mods from "./components/views/mods/ModsList";
import Cotizaciones from "./components/views/cotizaciones/CotizacionesList";
import { AuthProvider } from "./components/layout/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Stats />} />
            <Route path="verificaciones" element={<Verifications />} />
            <Route path="verificaciones/:id" element={<VerificationPreview />} />
            <Route path="calendario" element={<CalendarView />} />
            <Route path="cotizaciones" element={<Cotizaciones />} />
            <Route path="clientes" element={<Clients />} />
            <Route path="razonessociales" element={<VerifiedPlaces />} />
            <Route path="personas" element={<Persons />} />
            <Route path="mods" element={<Mods />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </AuthProvider>
);
