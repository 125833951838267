import { Table, Button, Space, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined, DollarCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import ModForm from "./forms/ModForm";
import PaymentForm from "./forms/PaymentForm";
import moment from "moment";

export default function ModsList() {
  const [modList, setModList] = useState();
  const [showModModal, setShowModModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedMod, setSelectedMod] = useState();

  const handleModFormCancel = () => {
    setShowModModal(false);
  };

  const handleModFormOpen = (record) => {
    setSelectedMod(record);
    setShowModModal(true);
  };

  const handlePaymentFormCancel = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentFormOpen = (record) => {
    setSelectedMod(record);
    setShowPaymentModal(true);
  };

  const handleDeleteMod = (record) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/mods/${record.ModID}`)
      .then((res) => {
        axios.get(`${process.env.REACT_APP_API_URL}/mods`).then((res) => {
          setModList(res.data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Titulo",
      dataIndex: "Titulo",
      key: "Titulo",
    },
    {
      title: "Tipo",
      dataIndex: "TipoMod",
      key: "TipoMod",
    },
    {
      title: "Fecha de petición",
      dataIndex: "FechaMod",
      key: "FechaMod",
      //render time ago and date
      render: (text, record) => (
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <span>{moment(record.FechaMod).format("DD/MM/YYYY")}</span>
          </Col>
          <Col span={24}>
            <span>{moment(record.FechaMod).fromNow()}</span>
          </Col>
        </Row>
      ),
    },
    {
      title: "Costo",
      dataIndex: "Costo",
      key: "Costo",
    },
    {
      title: "Pagos",
      dataIndex: "TotalPagado",
      key: "TotalPagado",
      render: (text, record) =>
        record.TotalPagado > 0 ? (
          record.Costo - record.TotalPagado === 0 ? (
            <span style={{ color: "green" }}>Pagado</span>
          ) : (
            <>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <span>Pagado:</span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <span>{record.TotalPagado}</span>
                </Col>
                <Col span={12}>
                  <span>Saldo:</span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <span>{record.Costo - record.TotalPagado}</span>
                </Col>
              </Row>
            </>
          )
        ) : (
          <span style={{ color: "red" }}>Sin pagos</span>
        ),
    },
    {
      title: "Estado",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Acciones",
      dataIndex: "Acciones",
      key: "Acciones",
      render: (text, record) => (
        <Space size="small">
          <Button type="primary" icon={<EditOutlined />} onClick={() => handleModFormOpen(record)} />
          <Button type="primary" icon={<DollarCircleFilled />} onClick={() => handlePaymentFormOpen(record)} />
          <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDeleteMod(record)} />
        </Space>
      ),
    },
  ];

  //fetch from api/mods
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/mods`)
      .then((res) => {
        setModList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row gutter={[0, 16]} style={{ width: "100%" }}>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => handleModFormOpen()}>
            Agregar Mod
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record) => record.ModID}
            columns={columns}
            dataSource={modList}
            expandable={{
              expandedRowRender: (record) => (
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    Descripción
                    <p style={{ margin: 0 }}>{record.Descripcion}</p>
                  </Col>
                  <Col span={16}>
                    Alcance
                    <p style={{ margin: 0 }}>{record.Alcance}</p>
                  </Col>
                </Row>
              ),
            }}
          />
        </Col>
      </Row>
      <ModForm visible={showModModal} onCancel={handleModFormCancel} setModList={setModList} record={selectedMod} />
      <PaymentForm visible={showPaymentModal} onCancel={handlePaymentFormCancel} setModList={setModList} record={selectedMod} />
    </>
  );
}
