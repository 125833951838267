import { Typography } from "antd";

export default function MainTitle(props) {
  const { title, marginBottom } = props;

  return (
    <Typography.Title level={3} type="primary" style={{ marginBottom: marginBottom + "px" }}>
      {title}
    </Typography.Title>
  );
}
