import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Esta página no existe"
        extra={
          <Link to="/">
            <Button type="primary">Volver al inicio</Button>
          </Link>
        }
      />
    </div>
  );
}
