import { Form, Input, Select, Button, Row, Col, message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";

export default function PVDomicilioForm(props) {
  const { visible, title, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [asentamientos, setAsentamientos] = useState();
  const [isOtraHidden, setIsOtraHidden] = useState(true);
  const otraRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    visible &&
      axios
        .get(`https://codigospostalesmx-94e44-default-rtdb.firebaseio.com/datos/${verification?.CodigoPostal}.json`)
        .then((res) => {
          setAsentamientos(res.data.colonias);
        })
        .catch((err) => {
          console.log(err);
          message.error("Error al cargar los asentamientos");
        });
    form.setFieldsValue(verification);
    if (verification?.Colonia === null) {
      setIsOtraHidden(true);
    } else {
      setIsOtraHidden(false);
    }
  }, [verification, form, visible]);

  const colResposivity = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  const onBlur = (e) => {
    if (e.target.value.length === 5) {
      axios
        .get(`https://codigospostalesmx-94e44-default-rtdb.firebaseio.com/datos/${e.target.value}.json`)
        .then((res) => {
          if (res.data !== null) {
            form.setFieldsValue({
              Municipio: res.data.municipio,
              Estado: res.data.estado,
              Ciudad: res.data.ciudad,
              Asentamiento: null,
            });
            setAsentamientos(res.data.colonias);
            console.log(res.data);
          } else {
            form.setFieldsValue({
              Colonia: null,
              Municipio: null,
              Estado: null,
              Ciudad: null,
              Asentamiento: null,
            });
            setAsentamientos(null);
            message.error("No se encontró el código postal");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Error al obtener los datos del código postal");
        });
    }
  };

  useEffect(() => {
    if (isOtraHidden) {
      form.setFieldsValue({
        Colonia: null,
      });
    } else {
      otraRef.current?.focus();
    }
  }, [isOtraHidden, verification, form]);

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.35}>
            <Row gutter={[40, 0]}>
              <Col {...colResposivity}>
                <Form.Item name="NombreCalle" label="Nombre de la calle">
                  <Input />
                </Form.Item>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item name="NumeroExterior" label="No. exterior">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="NumeroInterior" label="No. interior">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="EntreCalle1" label="Entre calle 1">
                  <Input />
                </Form.Item>
                <Form.Item name="EntreCalle2" label="Entre calle 2">
                  <Input />
                </Form.Item>
                <Form.Item name="LinkMaps" label="Link de maps">
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colResposivity}>
                <Form.Item name="CodigoPostal" label="Código postal">
                  <Input onBlur={(e) => onBlur(e)} />
                </Form.Item>
                <Form.Item name="Estado" label="Estado">
                  <Input />
                </Form.Item>
                <Form.Item name="Municipio" label="Municipio">
                  <Input />
                </Form.Item>
                <Form.Item name="Ciudad" label="Ciudad">
                  <Input />
                </Form.Item>
                <Form.Item name="Asentamiento" label="Asentamiento">
                  <Select
                    onChange={(value) => {
                      setTimeout(() => {
                        if (value === "otra") {
                          setIsOtraHidden(false);
                        } else {
                          setIsOtraHidden(true);
                        }
                      }, 350);
                    }}
                    placeholder="Selecciona un asentamiento"
                    {...clearSelectProps}
                  >
                    {asentamientos?.map((asentamiento) => (
                      <Select.Option key={asentamiento} value={asentamiento}>
                        {asentamiento}
                      </Select.Option>
                    ))}
                    <Select.Option key="otra" value="otra">
                      <span style={{ color: "red" }}>OTRA COLONIA</span>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="Colonia" label="Colonia" hidden={isOtraHidden}>
                  <Input ref={otraRef} />
                </Form.Item>
              </Col>
            </Row>
          </AnimateItems>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            axios
              .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
              .then(() => {
                axios.get(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`).then((res) => {
                  setVerification(res.data[0]);
                  setVisible(false);
                  message.success("Se guardaron los cambios");
                });
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar los campos");
              });
          }}
        >
          Guardar
        </Button>
      </Modal>
    </>
  );
}
