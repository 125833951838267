import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, message, Collapse, Switch, Tabs, Skeleton } from "antd";
import {
  FileTextOutlined,
  DollarOutlined,
  PrinterOutlined,
  PartitionOutlined,
  ShopOutlined,
  IdcardOutlined,
  PhoneOutlined,
  MobileOutlined,
  MailOutlined,
  EnvironmentOutlined,
  GoogleOutlined,
  BulbOutlined,
  MonitorOutlined,
  PicLeftOutlined,
  HeatMapOutlined,
  ClusterOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import TemplatePreview from "./TemplatePreview";
import VisitPreview from "./VisitPreview";
import MainTitle from "./MainTitle";
import Subtitle from "./Subtitle";
import DataGroup from "./DataGroup";
import DataItem from "./DataItem";
import AnimateItems from "../../utils/AnimateItems";

export default function VerificationPreview() {
  const { id } = useParams();
  const [verification, setVerification] = useState();
  const [loading, setLoading] = useState(true);
  const [hidden, setHidden] = useState(false);

  const templateFields = {
    solicitud_acta_contrato: {
      "Razón social": verification?.NombreCompletoPV,
      "Dato de identificación de la Razón social": verification?.DatoPV_T,
      "Clasificación del SCIAN": verification?.ClasificacionSCIAN,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      "Código postal": verification?.CodigoPostal,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Teléfono móvil Razón social": verification?.TelefonoMovilPV_T,
      "Correo electrónico de la Razón social": verification?.CorreoElectronicoPV_T,
      "Nombre de la persona que firma el contrato": verification?.NombreCompletoPF,
      "Dato de identificación de la persona que firma el contrato": verification?.DatoPF_T,
      "Teléfono móvil de la persona que firma el contrato": verification?.TelefonoMovilPF_T,
      "Correo electrónico de la persona que firma el contrato": verification?.CorreoElectronicoPF_T,
      "Nombre de la persona que atiende la visita": verification?.NombreCompletoPA,
      "Dato de identificación de la persona que atiende la visita": verification?.DatoPA_T,
      "Teléfono móvil de la persona que atiende la visita": verification?.TelefonoMovilPA_T,
      "Correo electrónico de la persona que atiende la visita": verification?.CorreoElectronicoPA_T,
      "Tensión eléctrica de suministro": verification?.TensionElectricaSuministro,
      "Capacidad de la subestación": verification?.Capacidad,
      "Tipo de instalación": verification?.TipoInstalacion,
      "Tipo de verificación": verification?.TipoVerificacion,
      "Carga instalada": verification?.CargaInstalada,
      "Alcance de la verificación": verification?.AlcanceVerificacion,
      "Fecha de recepción de la solicitud": verification?.FechaRecepcion,
      "Nombre de la persona que solicita la verificación": verification?.NombreCompletoPS,
      "Tipo de identificación de la persona que firma el contrato": verification?.TipoDatoPF_T,
      Monto: verification?.Monto,
      "Fecha del contrato": verification?.FechaContrato,
      "Cargo de la persona que atiende la visita": verification?.CargoPA,
      "Documento de origen del folio de identificación de la persona que atiende la visita": verification?.DocumentoOrigenPA,
      "Folio de la identificación de la persona que atiende la visita": verification?.FolioPA_T,
      "Entidad que expide la identificación de la persona que atiende la visita": verification?.ExpedidoPorPA,
      "Domicilio de la persona que atiende la visita": verification?.DomicilioPA_T,
    },
    solicitud: {
      "Razón social": verification?.NombreCompletoPV,
      "Dato de identificación de la Razón social": verification?.DatoPV_T,
      "Clasificación del SCIAN": verification?.ClasificacionSCIAN,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      "Código postal": verification?.CodigoPostal,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Teléfono móvil Razón social": verification?.TelefonoMovilPV_T,
      "Correo electrónico de la Razón social": verification?.CorreoElectronicoPV_T,
      "Nombre de la persona que firma el contrato": verification?.NombreCompletoPF,
      "Dato de identificación de la persona que firma el contrato": verification?.DatoPF_T,
      "Teléfono móvil de la persona que firma el contrato": verification?.TelefonoMovilPF_T,
      "Correo electrónico de la persona que firma el contrato": verification?.CorreoElectronicoPF_T,
      "Nombre de la persona que atiende la visita": verification?.NombreCompletoPA,
      "Dato de identificación de la persona que atiende la visita": verification?.DatoPA_T,
      "Teléfono móvil de la persona que atiende la visita": verification?.TelefonoMovilPA_T,
      "Correo electrónico de la persona que atiende la visita": verification?.CorreoElectronicoPA_T,
      "Tensión eléctrica de suministro": verification?.TensionElectricaSuministro,
      "Capacidad de la subestación": verification?.Capacidad,
      "Tipo de instalación": verification?.TipoInstalacion,
      "Tipo de verificación": verification?.TipoVerificacion,
      "Carga instalada": verification?.CargaInstalada,
      "Alcance de la verificación": verification?.AlcanceVerificacion,
      "Fecha de recepción de la solicitud": verification?.FechaRecepcion,
    },
    contrato: {
      "Nombre de la persona que solicita la verificación": verification?.NombreCompletoPS,
      "Nombre de la persona que firma el contrato": verification?.NombreCompletoPF,
      "Tipo de identificación de la persona que firma el contrato": verification?.TipoDatoPF_T,
      "Dato de identificación de la persona que firma el contrato": verification?.DatoPF_T,
      "Teléfono móvil de la persona que firma el contrato": verification?.TelefonoMovilPF_T,
      "Correo electrónico de la persona que firma el contrato": verification?.CorreoElectronicoPF_T,
      "Razón social": verification?.NombreCompletoPV,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      "Código postal": verification?.CodigoPostal,
      Ciudad: verification?.Ciudad,
      Municipio: verification?.Municipio,
      Estado: verification?.Estado,
      Monto: verification?.Monto,
      "Tensión eléctrica de suministro": verification?.TensionElectricaSuministro,
      "Capacidad de la subestación": verification?.Capacidad,
      "Tipo de instalación": verification?.TipoInstalacion,
      "Tipo de verificación": verification?.TipoVerificacion,
      "Carga instalada": verification?.CargaInstalada,
      "Alcance de la verificación": verification?.AlcanceVerificacion,
      "Fecha del contrato": verification?.FechaContrato,
    },
    acta: {
      "Razón social": verification?.NombreCompletoPV,
      "Clasificación del SCIAN": verification?.ClasificacionSCIAN,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      "Código postal": verification?.CodigoPostal,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Teléfono móvil Razón social": verification?.TelefonoMovilPV_T,
      "Correo electrónico de la Razón social": verification?.CorreoElectronicoPV_T,
      "Nombre de la persona que atiende la visita": verification?.NombreCompletoPA,
      "Cargo de la persona que atiende la visita": verification?.CargoPA,
      "Documento de origen del folio de identificación de la persona que atiende la visita": verification?.DocumentoOrigenPA,
      "Folio de la identificación de la persona que atiende la visita": verification?.FolioPA_T,
      "Entidad que expide la identificación de la persona que atiende la visita": verification?.ExpedidoPorPA,
      "Domicilio de la persona que atiende la visita": verification?.DomicilioPA_T,
    },
    informe: {
      "Razón social": verification?.NombreCompletoPV,
      "Número de control interno": verification?.ControlInterno,
    },
    portada: {
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Código postal": verification?.CodigoPostal,
      "Aplica NOM 007": verification?.Nom007,
      "Aplica NOM 013": verification?.Nom013,
      "Folio de la AMUVIE": verification?.FolioAmuvie,
    },
    anexob: {
      "Razón social": verification?.NombreCompletoPV,
      "Número de control interno": verification?.ControlInterno,
      "Nombre de la calle": verification?.NombreCalle,
      "Numero oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      Estado: verification?.Estado,
    },
    etiqueta: {
      "Número de control interno": verification?.ControlInterno,
      "Razón social": verification?.NombreCompletoPV,
      "Clasificación del SCIAN": verification?.ClasificacionSCIAN,
    },
    vistaprevia: {
      "Razón social": verification?.NombreCompletoPV,
      "Tipo de identificación de la Razón social": verification?.TipoDatoPV_T,
      "Dato de identificación de la Razón social": verification?.DatoPV_T,
      "Clasificación del SCIAN": verification?.ClasificacionSCIAN,
      "Capacidad de la subestación": verification?.Capacidad,
      "Carga instalada": verification?.CargaInstalada,
      "Alcance de la verificación": verification?.AlcanceVerificacion,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Código postal": verification?.CodigoPostal,
      "Teléfono móvil de la Razón social": verification?.TelefonoMovilPV_T,
      "Correo electrónico de la Razón social": verification?.CorreoElectronicoPV_T,
      "Nombre de la persona que solicita la verificación": verification?.NombreCompletoPS,
      "Tipo de identificación de la persona que solicita la verificación": verification?.TipoDatoPS_T,
      "Dato de identificación de la persona que solicita la verificación": verification?.DatoPS_T,
      "Teléfono móvil de la persona que solicita la verificación": verification?.TelefonoMovilPS_T,
      "Correo electrónico de la persona que solicita la verificación": verification?.CorreoElectronicoPS_T,
    },
    dictamen_007: {
      "Número de control interno": verification?.ControlInterno,
      "Fecha del dictamen (solicitud)": verification?.FechaRecepcion,
      "Razón social": verification?.NombreCompletoPV,
      "Tipo de verificación": verification?.TipoVerificacion,
      "Carga instalada": verification?.CargaInstalada,
      "Superficie para DPEA": verification?.SuperficieDPEA,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Código postal": verification?.CodigoPostal,
      "Nombre de la persona que firma el contrato": verification?.NombreCompletoPF,
      "Telefono móvil de la persona que firma el contrato": verification?.TelefonoMovilPF_T,
      "Correo electrónico de la persona que firma el contrato": verification?.CorreoElectronicoPF_T,
    },
    dictamen_013: {
      "Número de control interno": verification?.ControlInterno,
      "Fecha del dictamen (solicitud)": verification?.FechaRecepcion,
      "Razón social": verification?.NombreCompletoPV,
      "Tipo de verificación": verification?.TipoVerificacion,
      "Carga instalada": verification?.CargaInstalada,
      "Superficie para DPEA": verification?.SuperficieDPEA,
      "Tipo de vialidad": verification?.TipoVialidad,
      "Nombre de la calle": verification?.NombreCalle,
      "Número oficial": verification?.NumeroOficial,
      Colonia: verification?.ColoniaOAsentamiento,
      Municipio: verification?.Municipio,
      Ciudad: verification?.Ciudad,
      Estado: verification?.Estado,
      "Código postal": verification?.CodigoPostal,
      "Nombre de la persona que firma el contrato": verification?.NombreCompletoPF,
      "Telefono móvil de la persona que firma el contrato": verification?.TelefonoMovilPF_T,
      "Correo electrónico de la persona que firma el contrato": verification?.CorreoElectronicoPF_T,
    },
  };

  const responsiveTemplate = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 3,
    xl: 3,
  };

  const responsiveInfo = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12,
    xxl: 8,
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/verificaciones/${id}`)
      .then((res) => {
        setVerification(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }, [id]);

  return (
    <>
      <Row>
        <Col span={12}>
          {loading ? (
            <Skeleton.Input active paragraph={{ rows: 1 }} title={false} />
          ) : (
            <MainTitle title={verification?.ControlInterno} marginBottom={20} />
          )}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Switch
            checked={hidden}
            onChange={(checked) => setHidden(checked)}
            checkedChildren="Mostrar campos vacíos"
            unCheckedChildren="Ocultar campos vacíos"
            style={{ marginTop: "20px" }}
          />
        </Col>
      </Row>
      <Tabs>
        <Tabs.TabPane
          tab={
            <>
              <div style={{ display: "flex", flexDirection: "Column" }}>
                <AuditOutlined style={{ fontSize: "20px" }} />
                <span>Verificación</span>
              </div>
            </>
          }
          key="1"
        >
          <div>
            <AnimateItems delay={0.15}>
              <Subtitle title="Datos generales" marginTop={0} />
              <Row gutter={[16, 16]}>
                <Col {...responsiveInfo}>
                  {/* -------------------- GENERAL -------------------- */}
                  <DataGroup title={"General"} formName={"Información general"} mainData={verification} setMainData={setVerification}>
                    <DataItem
                      keyName="Descripción breve"
                      value={verification?.DescripcionBreve}
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<DollarOutlined />}
                      iconColor="#82cc4b "
                      keyName="Monto de contrato"
                      value={verification?.Monto}
                      valueType="double"
                      fieldWidth={8}
                      hidden={hidden}
                      prefix="$"
                      loading={loading}
                    />
                    <DataItem
                      icon={<PrinterOutlined />}
                      iconColor="#1890ff "
                      keyName="Folio AMUVIE"
                      value={verification?.FolioAmuvie}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- FECHAS -------------------- */}
                  <DataGroup title={"Fechas"} formName={"Fechas"} mainData={verification} setMainData={setVerification}>
                    <DataItem
                      keyName="Fecha de creación"
                      value={verification?.FechaCreacion}
                      valueType="date"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Fecha de la solicitud"
                      value={verification?.FechaRecepcion}
                      valueType="date"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Fecha del contrato"
                      value={verification?.FechaContrato}
                      valueType="date"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- NOTAS -------------------- */}
                  <DataGroup title={"Notas"} formName={"Notas"} mainData={verification} setMainData={setVerification}>
                    <DataItem
                      icon={<FileTextOutlined />}
                      iconColor="#1890ff"
                      keyName="Notas"
                      value={verification?.Notas}
                      valueType="text"
                      fieldWidth={4}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Col span={24}>
                  <Collapse bordered={false}>
                    <Collapse.Panel header="Progreso en los formatos">
                      <Row gutter={[16, 16]}>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Sol., Ctto. y Acta"
                            templateFields={templateFields.solicitud_acta_contrato}
                            loading={loading}
                            templateName="solicitud_contrato_acta"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Solicitud"
                            templateFields={templateFields.solicitud}
                            loading={loading}
                            templateName="solicitud"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Contrato"
                            templateFields={templateFields.contrato}
                            loading={loading}
                            templateName="contrato"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Acta"
                            templateFields={templateFields.acta}
                            loading={loading}
                            templateName="acta"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Informe"
                            templateFields={templateFields.informe}
                            loading={loading}
                            templateName="informe"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Portada"
                            templateFields={templateFields.portada}
                            loading={loading}
                            templateName="portada"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Anexo B"
                            templateFields={templateFields.anexob}
                            loading={loading}
                            templateName="anexob"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Etiqueta"
                            templateFields={templateFields.etiqueta}
                            loading={loading}
                            templateName="etiqueta"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Vista Previa"
                            templateFields={templateFields.vistaprevia}
                            loading={loading}
                            templateName="vistaprevia"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Dictamen 007"
                            templateFields={templateFields.dictamen_007}
                            loading={loading}
                            templateName="dictamen_007"
                            id={id}
                          />
                        </Col>
                        <Col {...responsiveTemplate}>
                          <TemplatePreview
                            title="Dictamen 013"
                            templateFields={templateFields.dictamen_013}
                            loading={loading}
                            templateName="dictamen_013"
                            id={id}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
              <Subtitle title="Datos de la Razón social" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col {...responsiveInfo}>
                  {/* -------------------- RAZON SOCIAL -------------------- */}
                  <DataGroup
                    title={!verification?.NombreCompletoPV ? "Razón social" : verification?.NombreCompletoPV}
                    subtitle={
                      verification?.TipoPersonaPV_T && verification?.NacionalidadPV_T
                        ? "Persona " + verification?.TipoPersonaPV_T + ", " + verification?.NacionalidadPV_T
                        : null
                    }
                    formName={"Información de la Razón social"}
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<ShopOutlined />}
                      iconColor="purple"
                      keyName="Nombre comercial"
                      value={verification?.NombreComercial}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PartitionOutlined />}
                      iconColor="orange"
                      keyName="Clasificación SCIAN"
                      value={verification?.ClasificacionSCIAN}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<IdcardOutlined />}
                      iconColor="brown"
                      keyName={verification?.TipoDatoPV_T ? verification?.TipoDatoPV_T : "Tipo de identificación"}
                      value={verification?.DatoPV_T}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PhoneOutlined />}
                      iconColor="#1890ff"
                      keyName="Teléfono fijo"
                      value={verification?.TelefonoFijoPV_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MobileOutlined />}
                      iconColor="indigo"
                      keyName="Teléfono móvil"
                      value={verification?.TelefonoMovilPV_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MailOutlined />}
                      iconColor="#1890ff"
                      keyName="Correo electrónico"
                      value={verification?.CorreoElectronicoPV_T}
                      valueType="email"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- DIRECCION -------------------- */}
                  <DataGroup
                    title={
                      !verification?.NombreCalle
                        ? "Domicilio"
                        : verification?.NombreCalle +
                          `${verification?.NumeroOficial ? " " + verification?.NumeroOficial : " S/N"}` +
                          `${verification?.ColoniaOAsentamiento ? ", Col. " + verification?.ColoniaOAsentamiento : ""}`
                    }
                    subtitle={
                      verification?.EntreCalle1 && verification?.EntreCalle2
                        ? `Entre ${verification?.EntreCalle1} y ${verification?.EntreCalle2}`
                        : null
                    }
                    formName={"Domicilio de la Razón social"}
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<EnvironmentOutlined />}
                      iconColor="red"
                      keyName="Código postal"
                      value={verification?.CodigoPostal}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Ciudad"
                      value={verification?.Ciudad}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Municipio"
                      value={verification?.Municipio}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Estado"
                      value={verification?.Estado}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<GoogleOutlined />}
                      iconColor="red"
                      keyName="Link Google Maps"
                      value={verification?.LinkMaps}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
              </Row>
              <Subtitle title="Personas involucradas" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col {...responsiveInfo}>
                  {/* -------------------- PERSONA QUE FIRMA -------------------- */}
                  <DataGroup
                    title={!verification?.NombreCompletoPF ? "Persona que firma el contrato" : `FIRMA: ${verification?.NombreCompletoPF}`}
                    subtitle={
                      verification?.TipoPersonaPF_T && verification?.NacionalidadPF_T
                        ? "Persona " + verification?.TipoPersonaPF_T + ", " + verification?.NacionalidadPF_T
                        : null
                    }
                    formName={"Persona que firma el contrato"}
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<IdcardOutlined />}
                      iconColor="brown"
                      keyName={verification?.TipoDatoPF_T ? verification?.TipoDatoPF_T : "Tipo de identificación"}
                      value={verification?.DatoPF_T}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PhoneOutlined />}
                      iconColor="#1890ff"
                      keyName="Teléfono fijo"
                      value={verification?.TelefonoFijoPF_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MobileOutlined />}
                      iconColor="indigo"
                      keyName="Teléfono móvil"
                      value={verification?.TelefonoMovilPF_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MailOutlined />}
                      iconColor="#1890ff"
                      keyName="Correo electrónico"
                      value={verification?.CorreoElectronicoPF_T}
                      valueType="email"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- PERSONA QUE ATIENDE -------------------- */}
                  <DataGroup
                    title={
                      !verification?.NombreCompletoPA
                        ? "Persona que atiende la visita"
                        : `ATIENDE: ${verification?.NombreCompletoPA} ${verification?.CargoPA ? "(" + verification?.CargoPA + ")" : null}`
                    }
                    subtitle={
                      verification?.TipoPersonaPA_T && verification?.NacionalidadPA_T
                        ? "Persona " + verification?.TipoPersonaPA_T + ", " + verification?.NacionalidadPA_T
                        : null
                    }
                    formName={"Persona que atiende la visita"}
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<IdcardOutlined />}
                      iconColor="brown"
                      keyName={verification?.TipoDatoPA_T ? verification?.TipoDatoPA_T : "Tipo de identificación"}
                      value={verification?.DatoPA_T}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PhoneOutlined />}
                      iconColor="#1890ff"
                      keyName="Teléfono fijo"
                      value={verification?.TelefonoFijoPA_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MobileOutlined />}
                      iconColor="indigo"
                      keyName="Teléfono móvil"
                      value={verification?.TelefonoMovilPA_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MailOutlined />}
                      iconColor="#1890ff"
                      keyName="Correo electrónico"
                      value={verification?.CorreoElectronicoPA_T}
                      valueType="email"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- PERSONA QUE SOLICITA -------------------- */}
                  <DataGroup
                    title={
                      !verification?.NombreCompletoPS
                        ? "Persona que solicita la verificación"
                        : `SOLICITA: ${verification?.NombreCompletoPS}`
                    }
                    subtitle={
                      verification?.TipoPersonaPS_T && verification?.NacionalidadPS_T
                        ? "Persona " + verification?.TipoPersonaPS_T + ", " + verification?.NacionalidadPS_T
                        : null
                    }
                    formName={"Persona que solicita la verificación"}
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<IdcardOutlined />}
                      iconColor="brown"
                      keyName={verification?.TipoDatoPS_T ? verification?.TipoDatoPS_T : "Tipo de identificación"}
                      value={verification?.DatoPS_T}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PhoneOutlined />}
                      iconColor="#1890ff"
                      keyName="Teléfono fijo"
                      value={verification?.TelefonoFijoPS_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MobileOutlined />}
                      iconColor="indigo"
                      keyName="Teléfono móvil"
                      value={verification?.TelefonoMovilPS_T}
                      valueType="phone"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MailOutlined />}
                      iconColor="#1890ff"
                      keyName="Correo electrónico"
                      value={verification?.CorreoElectronicoPS_T}
                      valueType="email"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
              </Row>
              <Subtitle title="Características de la instalación" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col {...responsiveInfo}>
                  {/* -------------------- CLASIFICACION -------------------- */}
                  <DataGroup
                    title={"Clasificación"}
                    formName="Clasificación de la verificación"
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      keyName="Tipo de verificación"
                      icon={<ReconciliationOutlined />}
                      iconColor="black"
                      value={verification?.TipoVerificacion}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<ClusterOutlined />}
                      iconColor="#1890ff"
                      keyName="Tipo de instalación"
                      value={verification?.TipoInstalacion}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Tipo de concentración pública"
                      icon={<TeamOutlined />}
                      iconColor="#1890ff"
                      value={verification?.TipoConcentracion}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Otro tipo de instalación"
                      icon={<TeamOutlined />}
                      iconColor="#1890ff"
                      value={verification?.TipoInstalacionOtro}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- VALORES -------------------- */}
                  <DataGroup
                    title={"Valores"}
                    formName="Valores de la instalación eléctrica"
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem
                      icon={<HeatMapOutlined />}
                      iconColor="red"
                      keyName="Tensión eléctrica de suministro"
                      value={verification?.TensionElectricaSuministro}
                      valueType="double"
                      suffix="V"
                      fieldWidth={16}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<BulbOutlined />}
                      iconColor="darkgoldenrod"
                      keyName="Carga instalada"
                      value={verification?.CargaInstalada}
                      valueType="double"
                      suffix="kW"
                      fieldWidth={16}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<MonitorOutlined />}
                      iconColor="#1890ff"
                      keyName="Alcance de la verificación"
                      value={verification?.AlcanceVerificacion}
                      valueType="double"
                      suffix="kW"
                      fieldWidth={16}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      icon={<PicLeftOutlined rotate={270} />}
                      iconColor="darkgreen"
                      keyName="Capacidad de la subestación"
                      value={verification?.Capacidad}
                      valueType="double"
                      suffix="kVA"
                      fieldWidth={16}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
                <Col {...responsiveInfo}>
                  {/* -------------------- TRANSFORMADOR -------------------- */}
                  <DataGroup
                    title={"Información del Transformador"}
                    formName="Información del Transformador"
                    mainData={verification}
                    setMainData={setVerification}
                  >
                    <DataItem keyName="Tipo" value={verification?.TipoTR} valueType="string" fieldWidth={8} hidden={hidden} />
                    <DataItem
                      keyName="Capacidad"
                      value={verification?.CapacidadTR}
                      valueType="double"
                      suffix="kVA"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem keyName="Marca" value={verification?.MarcaTR} valueType="string" fieldWidth={8} hidden={hidden} />
                    <DataItem
                      keyName="Número de serie"
                      value={verification?.NumeroSerieTR}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Impedancia"
                      value={verification?.ImpedanciaTR}
                      valueType="double"
                      suffix="%"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Fecha de fabricación"
                      value={verification?.FechaFabricacionTR}
                      valueType="string"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                    <DataItem
                      keyName="Eficiencia"
                      value={verification?.EficienciaTR}
                      valueType="double"
                      suffix="%"
                      fieldWidth={8}
                      hidden={hidden}
                      loading={loading}
                    />
                  </DataGroup>
                </Col>
              </Row>
            </AnimateItems>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <EnvironmentOutlined style={{ fontSize: "20px" }} />
                <span>Visita</span>
              </div>
            </>
          }
          key="2"
        >
          <VisitPreview id={id} verification={verification} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
