import { Row, Col } from "antd";

export default function Persons() {
  return (
    <>
      <Row>
        {/* 8 empty, 8 with data, 8 empty */}
        <Col span={6}></Col>
        <Col span={12}>
          <div style={{ backgroundColor: "#fff", color: "#001529", padding: "0 5px", marginBottom: "2px", display: "block" }}>
            {/* 2 columns */}
            <Row>
              <Col span={8}>

              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Col>
        <Col span={6}></Col>
      </Row>
    </>
  );
}
