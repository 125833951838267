const nacionalidades = [
  {
    value: 1,
    label: "Extranjera",
  },
  {
    value: 2,
    label: "Mexicana",
  },
];
const scian = [
  {
    value: 1,
    label: "CULTIVO DE SEMILLAS OLEAGINOSAS, LEGUMINOSAS Y CEREALES",
  },
  {
    value: 2,
    label: "CULTIVO DE HORTALIZAS",
  },
  {
    value: 3,
    label: "CULTIVO DE FRUTALES Y NUECES",
  },
  {
    value: 4,
    label: "CULTIVO EN INVERNADEROS Y OTRAS ESTRUCTURAS AGRÍCOLAS PROTEGIDAS, Y FLORICULTURA",
  },
  {
    value: 5,
    label: "OTROS CULTIVOS",
  },
  {
    value: 6,
    label: "EXPLOTACIÓN DE BOVINOS",
  },
  {
    value: 7,
    label: "EXPLOTACIÓN DE PORCINOS",
  },
  {
    value: 8,
    label: "EXPLOTACIÓN AVÍCOLA",
  },
  {
    value: 9,
    label: "EXPLOTACIÓN DE OVINOS Y CAPRINOS",
  },
  {
    value: 10,
    label: "ACUICULTURA",
  },
  {
    value: 11,
    label: "EXPLOTACIÓN DE OTROS ANIMALES",
  },
  {
    value: 12,
    label: "SILVICULTURA",
  },
  {
    value: 13,
    label: "VIVEROS FORESTALES Y RECOLECCIÓN DE PRODUCTOS FORESTALES",
  },
  {
    value: 14,
    label: "TALA DE ÁRBOLES",
  },
  {
    value: 15,
    label: "PESCA",
  },
  {
    value: 16,
    label: "CAZA Y CAPTURA",
  },
  {
    value: 17,
    label: "SERVICIOS RELACIONADOS CON LA AGRICULTURA",
  },
  {
    value: 18,
    label: "SERVICIOS RELACIONADOS CON LA CRÍA Y EXPLOTACIÓN DE ANIMALES",
  },
  {
    value: 19,
    label: "SERVICIOS RELACIONADOS CON EL APROVECHAMIENTO FORESTAL",
  },
  {
    value: 20,
    label: "EXTRACCIÓN DE PETRÓLEO Y GAS",
  },
  {
    value: 21,
    label: "MINERÍA DE CARBÓN MINERAL",
  },
  {
    value: 22,
    label: "MINERÍA DE MINERALES METÁLICOS",
  },
  {
    value: 23,
    label: "MINERÍA DE MINERALES NO METÁLICOS",
  },
  {
    value: 24,
    label: "SERVICIOS RELACIONADOS CON LA MINERÍA",
  },
  {
    value: 25,
    label: "GENERACIÓN, TRANSMISIÓN, DISTRIBUCIÓN Y COMERCIALIZACIÓN DE ENERGÍA ELÉCTRICA",
  },
  {
    value: 26,
    label: "CAPTACIÓN, TRATAMIENTO Y SUMINISTRO DE AGUA",
  },
  {
    value: 27,
    label: "SUMINISTRO DE GAS NATURAL POR DUCTOS AL CONSUMIDOR FINAL",
  },
  {
    value: 28,
    label: "EDIFICACIÓN RESIDENCIAL",
  },
  {
    value: 29,
    label: "EDIFICACIÓN NO RESIDENCIAL",
  },
  {
    value: 30,
    label: "CONSTRUCCIÓN DE OBRAS PARA EL SUMINISTRO DE AGUA, PETRÓLEO, GAS, ENERGÍA ELÉCTRICA Y TELECOMUNICACIONES",
  },
  {
    value: 31,
    label: "DIVISIÓN DE TERRENOS Y CONSTRUCCIÓN DE OBRAS DE URBANIZACIÓN",
  },
  {
    value: 32,
    label: "CONSTRUCCIÓN DE VÍAS DE COMUNICACIÓN",
  },
  {
    value: 33,
    label: "OTRAS CONSTRUCCIONES DE INGENIERÍA CIVIL",
  },
  {
    value: 34,
    label: "CIMENTACIONES, MONTAJE DE ESTRUCTURAS PREFABRICADAS Y TRABAJOS EN EXTERIORES",
  },
  {
    value: 35,
    label: "INSTALACIONES Y EQUIPAMIENTO EN CONSTRUCCIONES",
  },
  {
    value: 36,
    label: "TRABAJOS DE ACABADOS EN EDIFICACIONES",
  },
  {
    value: 37,
    label: "OTROS TRABAJOS ESPECIALIZADOS PARA LA CONSTRUCCIÓN",
  },
  {
    value: 38,
    label: "ELABORACIÓN DE ALIMENTOS PARA ANIMALES",
  },
  {
    value: 39,
    label: "MOLIENDA DE GRANOS Y DE SEMILLAS Y OBTENCIÓN DE ACEITES Y GRASAS",
  },
  {
    value: 40,
    label: "ELABORACIÓN DE AZÚCARES, CHOCOLATES, DULCES Y SIMILARES",
  },
  {
    value: 41,
    label: "CONSERVACIÓN DE FRUTAS, VERDURAS, GUISOS Y OTROS ALIMENTOS PREPARADOS",
  },
  {
    value: 42,
    label: "ELABORACIÓN DE PRODUCTOS LÁCTEOS",
  },
  {
    value: 43,
    label: "MATANZA, EMPACADO Y PROCESAMIENTO DE CARNE DE GANADO, AVES Y OTROS ANIMALES COMESTIBLES",
  },
  {
    value: 44,
    label: "PREPARACIÓN Y ENVASADO DE PESCADOS Y MARISCOS",
  },
  {
    value: 45,
    label: "ELABORACIÓN DE PRODUCTOS DE PANADERÍA Y TORTILLAS",
  },
  {
    value: 46,
    label: "OTRAS INDUSTRIAS ALIMENTARIAS",
  },
  {
    value: 47,
    label: "INDUSTRIA DE LAS BEBIDAS",
  },
  {
    value: 48,
    label: "INDUSTRIA DEL TABACO",
  },
  {
    value: 49,
    label: "PREPARACIÓN E HILADO DE FIBRAS TEXTILES, Y FABRICACIÓN DE HILOS",
  },
  {
    value: 50,
    label: "FABRICACIÓN DE TELAS",
  },
  {
    value: 51,
    label: "ACABADO DE PRODUCTOS TEXTILES Y FABRICACIÓN DE TELAS RECUBIERTAS",
  },
  {
    value: 52,
    label: "CONFECCIÓN DE ALFOMBRAS, BLANCOS Y SIMILARES",
  },
  {
    value: 53,
    label: "FABRICACIÓN DE OTROS PRODUCTOS TEXTILES, EXCEPTO PRENDAS DE VESTIR",
  },
  {
    value: 54,
    label: "FABRICACIÓN DE PRENDAS DE VESTIR DE TEJIDO DE PUNTO",
  },
  {
    value: 55,
    label: "CONFECCIÓN DE PRENDAS DE VESTIR",
  },
  {
    value: 56,
    label: "CONFECCIÓN DE ACCESORIOS DE VESTIR Y OTRAS PRENDAS DE VESTIR NO CLASIFICADOS EN OTRA PARTE",
  },
  {
    value: 57,
    label: "CURTIDO Y ACABADO DE CUERO Y PIEL",
  },
  {
    value: 58,
    label: "FABRICACIÓN DE CALZADO",
  },
  {
    value: 59,
    label: "FABRICACIÓN DE OTROS PRODUCTOS DE CUERO, PIEL Y MATERIALES SUCEDÁNEOS",
  },
  {
    value: 60,
    label: "ASERRADO Y CONSERVACIÓN DE LA MADERA",
  },
  {
    value: 61,
    label: "FABRICACIÓN DE LAMINADOS Y AGLUTINADOS DE MADERA",
  },
  {
    value: 62,
    label: "FABRICACIÓN DE OTROS PRODUCTOS DE MADERA",
  },
  {
    value: 63,
    label: "FABRICACIÓN DE PULPA, PAPEL Y CARTÓN",
  },
  {
    value: 64,
    label: "FABRICACIÓN DE PRODUCTOS DE CARTÓN Y PAPEL",
  },
  {
    value: 65,
    label: "IMPRESIÓN E INDUSTRIAS CONEXAS",
  },
  {
    value: 66,
    label: "FABRICACIÓN DE PRODUCTOS DERIVADOS DEL PETRÓLEO Y DEL CARBÓN",
  },
  {
    value: 67,
    label: "FABRICACIÓN DE PRODUCTOS QUÍMICOS BÁSICOS",
  },
  {
    value: 68,
    label: "FABRICACIÓN DE RESINAS Y HULES SINTÉTICOS, Y FIBRAS QUÍMICAS",
  },
  {
    value: 69,
    label: "FABRICACIÓN DE FERTILIZANTES, PESTICIDAS Y OTROS AGROQUÍMICOS",
  },
  {
    value: 70,
    label: "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS",
  },
  {
    value: 71,
    label: "FABRICACIÓN DE PINTURAS, RECUBRIMIENTOS Y ADHESIVOS",
  },
  {
    value: 72,
    label: "FABRICACIÓN DE JABONES, LIMPIADORES Y PREPARACIONES DE TOCADOR",
  },
  {
    value: 73,
    label: "FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS",
  },
  {
    value: 74,
    label: "FABRICACIÓN DE PRODUCTOS DE PLÁSTICO",
  },
  {
    value: 75,
    label: "FABRICACIÓN DE PRODUCTOS DE HULE",
  },
  {
    value: 76,
    label: "FABRICACIÓN DE PRODUCTOS A BASE DE ARCILLAS Y MINERALES REFRACTARIOS",
  },
  {
    value: 77,
    label: "FABRICACIÓN DE VIDRIO Y PRODUCTOS DE VIDRIO",
  },
  {
    value: 78,
    label: "FABRICACIÓN DE CEMENTO Y PRODUCTOS DE CONCRETO",
  },
  {
    value: 79,
    label: "FABRICACIÓN DE CAL, YESO Y PRODUCTOS DE YESO",
  },
  {
    value: 80,
    label: "FABRICACIÓN DE OTROS PRODUCTOS A BASE DE MINERALES NO METÁLICOS",
  },
  {
    value: 81,
    label: "INDUSTRIA BÁSICA DEL HIERRO Y DEL ACERO",
  },
  {
    value: 82,
    label: "FABRICACIÓN DE PRODUCTOS DE HIERRO Y ACERO",
  },
  {
    value: 83,
    label: "INDUSTRIA BÁSICA DEL ALUMINIO",
  },
  {
    value: 84,
    label: "INDUSTRIAS DE METALES NO FERROSOS, EXCEPTO ALUMINIO",
  },
  {
    value: 85,
    label: "MOLDEO POR FUNDICIÓN DE PIEZAS METÁLICAS",
  },
  {
    value: 86,
    label: "FABRICACIÓN DE PRODUCTOS METÁLICOS FORJADOS Y TROQUELADOS",
  },
  {
    value: 87,
    label: "FABRICACIÓN DE HERRAMIENTAS DE MANO SIN MOTOR Y UTENSILIOS DE COCINA METÁLICOS",
  },
  {
    value: 88,
    label: "FABRICACIÓN DE ESTRUCTURAS METÁLICAS Y PRODUCTOS DE HERRERÍA",
  },
  {
    value: 89,
    label: "FABRICACIÓN DE CALDERAS, TANQUES Y ENVASES METÁLICOS",
  },
  {
    value: 90,
    label: "FABRICACIÓN DE HERRAJES Y CERRADURAS",
  },
  {
    value: 91,
    label: "FABRICACIÓN DE ALAMBRE, PRODUCTOS DE ALAMBRE Y RESORTES",
  },
  {
    value: 92,
    label: "MAQUINADO DE PIEZAS Y FABRICACIÓN DE TORNILLOS",
  },
  {
    value: 93,
    label: "RECUBRIMIENTOS Y TERMINADOS METÁLICOS",
  },
  {
    value: 94,
    label: "FABRICACIÓN DE OTROS PRODUCTOS METÁLICOS",
  },
  {
    value: 95,
    label: "FABRICACIÓN DE MAQUINARIA Y EQUIPO AGROPECUARIO, PARA LA CONSTRUCCIÓN Y PARA LA INDUSTRIA EXTRACTIVA",
  },
  {
    value: 96,
    label: "FABRICACIÓN DE MAQUINARIA Y EQUIPO PARA LAS INDUSTRIAS MANUFACTURERAS, EXCEPTO LA METALMECÁNICA",
  },
  {
    value: 97,
    label: "FABRICACIÓN DE MAQUINARIA Y EQUIPO PARA EL COMERCIO Y LOS SERVICIOS",
  },
  {
    value: 98,
    label: "FABRICACIÓN DE EQUIPO DE AIRE ACONDICIONADO, CALEFACCIÓN, Y DE REFRIGERACIÓN INDUSTRIAL Y COMERCIAL",
  },
  {
    value: 99,
    label: "FABRICACIÓN DE MAQUINARIA Y EQUIPO PARA LA INDUSTRIA METALMECÁNICA",
  },
  {
    value: 100,
    label: "FABRICACIÓN DE MOTORES DE COMBUSTIÓN INTERNA, TURBINAS Y TRANSMISIONES",
  },
  {
    value: 101,
    label: "FABRICACIÓN DE OTRA MAQUINARIA Y EQUIPO PARA LA INDUSTRIA EN GENERAL",
  },
  {
    value: 102,
    label: "FABRICACIÓN DE COMPUTADORAS Y EQUIPO PERIFÉRICO",
  },
  {
    value: 103,
    label: "FABRICACIÓN DE EQUIPO DE COMUNICACIÓN",
  },
  {
    value: 104,
    label: "FABRICACIÓN DE EQUIPO DE AUDIO Y DE VIDEO",
  },
  {
    value: 105,
    label: "FABRICACIÓN DE COMPONENTES ELECTRÓNICOS",
  },
  {
    value: 106,
    label: "FABRICACIÓN DE INSTRUMENTOS DE MEDICIÓN, CONTROL, NAVEGACIÓN, Y EQUIPO MÉDICO ELECTRÓNICO",
  },
  {
    value: 107,
    label: "FABRICACIÓN Y REPRODUCCIÓN DE MEDIOS MAGNÉTICOS Y ÓPTICOS",
  },
  {
    value: 108,
    label: "FABRICACIÓN DE ACCESORIOS DE ILUMINACIÓN",
  },
  {
    value: 109,
    label: "FABRICACIÓN DE APARATOS ELÉCTRICOS DE USO DOMÉSTICO",
  },
  {
    value: 110,
    label: "FABRICACIÓN DE EQUIPO DE GENERACIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA",
  },
  {
    value: 111,
    label: "FABRICACIÓN DE OTROS EQUIPOS Y ACCESORIOS ELÉCTRICOS",
  },
  {
    value: 112,
    label: "FABRICACIÓN DE AUTOMÓVILES Y CAMIONES",
  },
  {
    value: 113,
    label: "FABRICACIÓN DE CARROCERÍAS Y REMOLQUES",
  },
  {
    value: 114,
    label: "FABRICACIÓN DE PARTES PARA VEHÍCULOS AUTOMOTORES",
  },
  {
    value: 115,
    label: "FABRICACIÓN DE EQUIPO AEROESPACIAL",
  },
  {
    value: 116,
    label: "FABRICACIÓN DE EQUIPO FERROVIARIO",
  },
  {
    value: 117,
    label: "FABRICACIÓN DE EMBARCACIONES",
  },
  {
    value: 118,
    label: "FABRICACIÓN DE OTRO EQUIPO DE TRANSPORTE",
  },
  {
    value: 119,
    label: "FABRICACIÓN DE MUEBLES, EXCEPTO DE OFICINA Y ESTANTERÍA",
  },
  {
    value: 120,
    label: "FABRICACIÓN DE MUEBLES DE OFICINA Y ESTANTERÍA",
  },
  {
    value: 121,
    label: "FABRICACIÓN DE COLCHONES, PERSIANAS Y CORTINEROS",
  },
  {
    value: 122,
    label: "FABRICACIÓN DE EQUIPO NO ELECTRÓNICO Y MATERIAL DESECHABLE DE USO MÉDICO, DENTAL Y PARA LABORATORIO, Y ARTÍCULOS OFTÁLMICOS",
  },
  {
    value: 123,
    label: "OTRAS INDUSTRIAS MANUFACTURERAS",
  },
  {
    value: 124,
    label: "COMERCIO AL POR MAYOR DE ABARROTES Y ALIMENTOS",
  },
  {
    value: 125,
    label: "COMERCIO AL POR MAYOR DE BEBIDAS, HIELO Y TABACO",
  },
  {
    value: 126,
    label: "COMERCIO AL POR MAYOR DE PRODUCTOS TEXTILES Y CALZADO",
  },
  {
    value: 127,
    label: "COMERCIO AL POR MAYOR DE PRODUCTOS FARMACÉUTICOS",
  },
  {
    value: 128,
    label: "COMERCIO AL POR MAYOR DE ARTÍCULOS DE PERFUMERÍA, COSMÉTICOS Y JOYERÍA",
  },
  {
    value: 129,
    label: "COMERCIO AL POR MAYOR DE DISCOS, JUGUETES Y ARTÍCULOS DEPORTIVOS",
  },
  {
    value: 130,
    label: "COMERCIO AL POR MAYOR DE ARTÍCULOS DE PAPELERÍA, LIBROS, REVISTAS Y PERIÓDICOS",
  },
  {
    value: 131,
    label: "COMERCIO AL POR MAYOR DE ELECTRODOMÉSTICOS MENORES Y APARATOS DE LÍNEA BLANCA",
  },
  {
    value: 132,
    label: "COMERCIO AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y FORESTALES",
  },
  {
    value: 133,
    label: "COMERCIO AL POR MAYOR DE MATERIAS PRIMAS PARA LA INDUSTRIA",
  },
  {
    value: 134,
    label: "COMERCIO AL POR MAYOR DE MATERIALES DE DESECHO",
  },
  {
    value: 135,
    label: "COMERCIO AL POR MAYOR DE MAQUINARIA Y EQUIPO AGROPECUARIO, FORESTAL Y PARA LA PESCA",
  },
  {
    value: 136,
    label: "COMERCIO AL POR MAYOR DE MAQUINARIA Y EQUIPO PARA LA INDUSTRIA",
  },
  {
    value: 137,
    label: "COMERCIO AL POR MAYOR DE MAQUINARIA Y EQUIPO PARA LOS SERVICIOS Y PARA ACTIVIDADES COMERCIALES",
  },
  {
    value: 138,
    label: "COMERCIO AL POR MAYOR DE MOBILIARIO Y EQUIPO DE CÓMPUTO Y DE OFICINA, Y DE OTRA MAQUINARIA Y EQUIPO DE USO GENERAL",
  },
  {
    value: 139,
    label: "COMERCIO AL POR MAYOR DE CAMIONES Y DE PARTES Y REFACCIONES NUEVAS PARA AUTOMÓVILES, CAMIONETAS Y CAMIONES",
  },
  {
    value: 140,
    label: "INTERMEDIACIÓN DE COMERCIO AL POR MAYOR, EXCEPTO A TRAVÉS DE INTERNET Y DE OTROS MEDIOS ELECTRÓNICOS",
  },
  {
    value: 141,
    label: "INTERMEDIACIÓN DE COMERCIO AL POR MAYOR EXCLUSIVAMENTE A TRAVÉS DE INTERNET Y OTROS MEDIOS ELECTRÓNICOS",
  },
  {
    value: 142,
    label: "COMERCIO AL POR MENOR DE ABARROTES Y ALIMENTOS",
  },
  {
    value: 143,
    label: "COMERCIO AL POR MENOR DE BEBIDAS, HIELO Y TABACO",
  },
  {
    value: 144,
    label: "COMERCIO AL POR MENOR EN TIENDAS DE AUTOSERVICIO",
  },
  {
    value: 145,
    label: "COMERCIO AL POR MENOR EN TIENDAS DEPARTAMENTALES",
  },
  {
    value: 146,
    label: "COMERCIO AL POR MENOR DE PRODUCTOS TEXTILES, EXCEPTO ROPA",
  },
  {
    value: 147,
    label: "COMERCIO AL POR MENOR DE ROPA, BISUTERÍA Y ACCESORIOS DE VESTIR",
  },
  {
    value: 148,
    label: "COMERCIO AL POR MENOR DE CALZADO",
  },
  {
    value: 149,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS PARA EL CUIDADO DE LA SALUD",
  },
  {
    value: 150,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS DE PERFUMERÍA Y JOYERÍA",
  },
  {
    value: 151,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS PARA EL ESPARCIMIENTO",
  },
  {
    value: 152,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS DE PAPELERÍA, LIBROS, REVISTAS Y PERIÓDICOS",
  },
  {
    value: 153,
    label: "COMERCIO AL POR MENOR DE MASCOTAS, REGALOS, ARTÍCULOS RELIGIOSOS, DESECHABLES, ARTESANÍAS Y OTROS ARTÍCULOS DE USO PERSONAL",
  },
  {
    value: 154,
    label: "COMERCIO AL POR MENOR DE MUEBLES PARA EL HOGAR Y OTROS ENSERES DOMÉSTICOS",
  },
  {
    value: 155,
    label: "COMERCIO AL POR MENOR DE MOBILIARIO, EQUIPO Y ACCESORIOS DE CÓMPUTO, TELÉFONOS Y OTROS APARATOS DE COMUNICACIÓN",
  },
  {
    value: 156,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS PARA LA DECORACIÓN DE INTERIORES",
  },
  {
    value: 157,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS USADOS",
  },
  {
    value: 158,
    label: "COMERCIO AL POR MENOR DE ARTÍCULOS DE FERRETERÍA, TLAPALERÍA Y VIDRIOS",
  },
  {
    value: 159,
    label: "COMERCIO AL POR MENOR DE AUTOMÓVILES Y CAMIONETAS",
  },
  {
    value: 160,
    label: "COMERCIO AL POR MENOR DE PARTES Y REFACCIONES PARA AUTOMÓVILES, CAMIONETAS Y CAMIONES",
  },
  {
    value: 161,
    label: "COMERCIO AL POR MENOR DE MOTOCICLETAS Y OTROS VEHÍCULOS DE MOTOR",
  },
  {
    value: 162,
    label: "COMERCIO AL POR MENOR DE COMBUSTIBLES, ACEITES Y GRASAS LUBRICANTES",
  },
  {
    value: 163,
    label: "COMERCIO AL POR MENOR EXCLUSIVAMENTE A TRAVÉS DE INTERNET, Y CATÁLOGOS IMPRESOS, TELEVISIÓN Y SIMILARES",
  },
  {
    value: 164,
    label: "TRANSPORTE AÉREO REGULAR",
  },
  {
    value: 165,
    label: "TRANSPORTE AÉREO NO REGULAR",
  },
  {
    value: 166,
    label: "TRANSPORTE POR FERROCARRIL",
  },
  {
    value: 167,
    label: "TRANSPORTE MARÍTIMO",
  },
  {
    value: 168,
    label: "TRANSPORTE POR AGUAS INTERIORES",
  },
  {
    value: 169,
    label: "AUTOTRANSPORTE DE CARGA GENERAL",
  },
  {
    value: 170,
    label: "AUTOTRANSPORTE DE CARGA ESPECIALIZADO",
  },
  {
    value: 171,
    label: "TRANSPORTE COLECTIVO URBANO Y SUBURBANO DE PASAJEROS DE RUTA FIJA",
  },
  {
    value: 172,
    label: "TRANSPORTE COLECTIVO FORÁNEO DE PASAJEROS DE RUTA FIJA",
  },
  {
    value: 173,
    label: "SERVICIO DE TAXIS Y LIMUSINAS",
  },
  {
    value: 174,
    label: "TRANSPORTE ESCOLAR Y DE PERSONAL",
  },
  {
    value: 175,
    label: "ALQUILER DE AUTOBUSES CON CHOFER",
  },
  {
    value: 176,
    label: "OTRO TRANSPORTE TERRESTRE DE PASAJEROS",
  },
  {
    value: 177,
    label: "TRANSPORTE DE PETRÓLEO CRUDO POR DUCTOS",
  },
  {
    value: 178,
    label: "TRANSPORTE DE GAS NATURAL POR DUCTOS",
  },
  {
    value: 179,
    label: "TRANSPORTE POR DUCTOS DE OTROS PRODUCTOS",
  },
  {
    value: 180,
    label: "TRANSPORTE TURÍSTICO POR TIERRA",
  },
  {
    value: 181,
    label: "TRANSPORTE TURÍSTICO POR AGUA",
  },
  {
    value: 182,
    label: "OTRO TRANSPORTE TURÍSTICO",
  },
  {
    value: 183,
    label: "SERVICIOS RELACIONADOS CON EL TRANSPORTE AÉREO",
  },
  {
    value: 184,
    label: "SERVICIOS RELACIONADOS CON EL TRANSPORTE POR FERROCARRIL",
  },
  {
    value: 185,
    label: "SERVICIOS RELACIONADOS CON EL TRANSPORTE POR AGUA",
  },
  {
    value: 186,
    label: "SERVICIOS RELACIONADOS CON EL TRANSPORTE POR CARRETERA",
  },
  {
    value: 187,
    label: "SERVICIOS DE INTERMEDIACIÓN PARA EL TRANSPORTE DE CARGA",
  },
  {
    value: 188,
    label: "OTROS SERVICIOS RELACIONADOS CON EL TRANSPORTE",
  },
  {
    value: 189,
    label: "SERVICIOS POSTALES",
  },
  {
    value: 190,
    label: "SERVICIOS DE MENSAJERÍA Y PAQUETERÍA FORÁNEA",
  },
  {
    value: 191,
    label: "SERVICIOS DE MENSAJERÍA Y PAQUETERÍA LOCAL",
  },
  {
    value: 192,
    label: "SERVICIOS DE ALMACENAMIENTO",
  },
  {
    value: 193,
    label: "EDICIÓN DE PERIÓDICOS, REVISTAS, LIBROS Y SIMILARES, Y EDICIÓN DE ESTAS PUBLICACIONES INTEGRADA CON LA IMPRESIÓN",
  },
  {
    value: 194,
    label: "EDICIÓN DE SOFTWARE Y EDICIÓN DE SOFTWARE INTEGRADA CON LA REPRODUCCIÓN",
  },
  {
    value: 195,
    label: "INDUSTRIA FÍLMICA Y DEL VIDEO",
  },
  {
    value: 196,
    label: "INDUSTRIA DEL SONIDO",
  },
  {
    value: 197,
    label: "TRANSMISIÓN DE PROGRAMAS DE RADIO Y TELEVISIÓN",
  },
  {
    value: 198,
    label: "PRODUCCIÓN DE PROGRAMACIÓN DE CANALES PARA SISTEMAS DE TELEVISIÓN POR CABLE O SATELITALES",
  },
  {
    value: 199,
    label: "OPERADORES DE SERVICIOS DE TELECOMUNICACIONES ALÁMBRICAS E INALÁMBRICAS",
  },
  {
    value: 200,
    label: null,
  },
  {
    value: 201,
    label: "OPERADORES DE SERVICIOS DE TELECOMUNICACIONES VÍA SATÉLITE",
  },
  {
    value: 202,
    label: "OTROS SERVICIOS DE TELECOMUNICACIONES",
  },
  {
    value: 203,
    label: "PROCESAMIENTO ELECTRÓNICO DE INFORMACIÓN, HOSPEDAJE Y OTROS SERVICIOS RELACIONADOS",
  },
  {
    value: 204,
    label: "OTROS SERVICIOS DE INFORMACIÓN",
  },
  {
    value: 205,
    label: "BANCA CENTRAL",
  },
  {
    value: 206,
    label: "BANCA MÚLTIPLE",
  },
  {
    value: 207,
    label: "INSTITUCIONES FINANCIERAS DE FOMENTO ECONÓMICO",
  },
  {
    value: 208,
    label: "UNIONES DE CRÉDITO E INSTITUCIONES DE AHORRO",
  },
  {
    value: 209,
    label: "OTRAS INSTITUCIONES DE INTERMEDIACIÓN CREDITICIA Y FINANCIERA NO BURSÁTIL",
  },
  {
    value: 210,
    label: "SERVICIOS RELACIONADOS CON LA INTERMEDIACIÓN CREDITICIA NO BURSÁTIL",
  },
  {
    value: 211,
    label: "CASAS DE BOLSA, CASAS DE CAMBIO Y CENTROS CAMBIARIOS",
  },
  {
    value: 212,
    label: "BOLSA DE VALORES",
  },
  {
    value: 213,
    label: "ASESORÍA EN INVERSIONES Y OTROS SERVICIOS RELACIONADOS CON LA INTERMEDIACIÓN BURSÁTIL",
  },
  {
    value: 214,
    label: "COMPAÑÍAS DE SEGUROS Y FIANZAS",
  },
  {
    value: 215,
    label: "SERVICIOS RELACIONADOS CON LOS SEGUROS Y LAS FIANZAS, Y ADMINISTRACIÓN DE FONDOS PARA EL RETIRO",
  },
  {
    value: 216,
    label: "ALQUILER SIN INTERMEDIACIÓN DE BIENES RAÍCES",
  },
  {
    value: 217,
    label: "INMOBILIARIAS Y CORREDORES DE BIENES RAÍCES",
  },
  {
    value: 218,
    label: "SERVICIOS RELACIONADOS CON LOS SERVICIOS INMOBILIARIOS",
  },
  {
    value: 219,
    label: "ALQUILER DE AUTOMÓVILES, CAMIONES Y OTROS TRANSPORTES TERRESTRES",
  },
  {
    value: 220,
    label: "ALQUILER DE ARTÍCULOS PARA EL HOGAR Y PERSONALES",
  },
  {
    value: 221,
    label: "CENTROS GENERALES DE ALQUILER",
  },
  {
    value: 222,
    label: "ALQUILER DE MAQUINARIA Y EQUIPO AGROPECUARIO, PESQUERO, INDUSTRIAL, COMERCIAL Y DE SERVICIOS",
  },
  {
    value: 223,
    label: "SERVICIOS DE ALQUILER DE MARCAS REGISTRADAS, PATENTES Y FRANQUICIAS",
  },
  {
    value: 224,
    label: "SERVICIOS LEGALES",
  },
  {
    value: 225,
    label: "SERVICIOS DE CONTABILIDAD, AUDITORÍA Y SERVICIOS RELACIONADOS",
  },
  {
    value: 226,
    label: "SERVICIOS DE ARQUITECTURA, INGENIERÍA Y ACTIVIDADES RELACIONADAS",
  },
  {
    value: 227,
    label: "DISEÑO ESPECIALIZADO",
  },
  {
    value: 228,
    label: "SERVICIOS DE DISEÑO DE SISTEMAS DE CÓMPUTO Y SERVICIOS RELACIONADOS",
  },
  {
    value: 229,
    label: "SERVICIOS DE CONSULTORÍA ADMINISTRATIVA, CIENTÍFICA Y TÉCNICA",
  },
  {
    value: 230,
    label: "SERVICIOS DE INVESTIGACIÓN CIENTÍFICA Y DESARROLLO",
  },
  {
    value: 231,
    label: "SERVICIOS DE PUBLICIDAD Y ACTIVIDADES RELACIONADAS",
  },
  {
    value: 232,
    label: "OTROS SERVICIOS PROFESIONALES, CIENTÍFICOS Y TÉCNICOS",
  },
  {
    value: 233,
    label: "CORPORATIVOS",
  },
  {
    value: 234,
    label: "SERVICIOS DE ADMINISTRACIÓN DE NEGOCIOS",
  },
  {
    value: 235,
    label: "SERVICIOS COMBINADOS DE APOYO EN INSTALACIONES",
  },
  {
    value: 236,
    label: "SERVICIOS DE EMPLEO",
  },
  {
    value: 237,
    label: "SERVICIOS DE APOYO SECRETARIAL, FOTOCOPIADO, COBRANZA, INVESTIGACIÓN CREDITICIA Y SIMILARES",
  },
  {
    value: 238,
    label: "AGENCIAS DE VIAJES Y SERVICIOS DE RESERVACIONES",
  },
  {
    value: 239,
    label: "SERVICIOS DE INVESTIGACIÓN, PROTECCIÓN Y SEGURIDAD",
  },
  {
    value: 240,
    label: "SERVICIOS DE LIMPIEZA",
  },
  {
    value: 241,
    label: "OTROS SERVICIOS DE APOYO A LOS NEGOCIOS",
  },
  {
    value: 242,
    label: "SERVICIOS DE REMEDIACIÓN, RECUPERACIÓN, Y OTROS SERVICIOS DE MANEJO DE RESIDUOS",
  },
  {
    value: 243,
    label: "ESCUELAS DE EDUCACIÓN BÁSICA, MEDIA Y PARA NECESIDADES ESPECIALES",
  },
  {
    value: 244,
    label: "ESCUELAS DE EDUCACIÓN TÉCNICA SUPERIOR",
  },
  {
    value: 245,
    label: "ESCUELAS DE EDUCACIÓN SUPERIOR",
  },
  {
    value: 246,
    label: "ESCUELAS COMERCIALES, DE COMPUTACIÓN Y DE CAPACITACIÓN PARA EJECUTIVOS",
  },
  {
    value: 247,
    label: "ESCUELAS DE OFICIOS",
  },
  {
    value: 248,
    label: "OTROS SERVICIOS EDUCATIVOS",
  },
  {
    value: 249,
    label: "SERVICIOS DE APOYO A LA EDUCACIÓN",
  },
  {
    value: 250,
    label: "CONSULTORIOS MÉDICOS",
  },
  {
    value: 251,
    label: "CONSULTORIOS DENTALES",
  },
  {
    value: 252,
    label: "OTROS CONSULTORIOS PARA EL CUIDADO DE LA SALUD",
  },
  {
    value: 253,
    label: "CENTROS PARA LA ATENCIÓN DE PACIENTES QUE NO REQUIEREN HOSPITALIZACIÓN",
  },
  {
    value: 254,
    label: "LABORATORIOS MÉDICOS Y DE DIAGNÓSTICO",
  },
  {
    value: 255,
    label: "SERVICIOS DE ENFERMERÍA A DOMICILIO",
  },
  {
    value: 256,
    label: "SERVICIOS DE AMBULANCIAS, DE BANCOS DE ÓRGANOS Y OTROS SERVICIOS AUXILIARES AL TRATAMIENTO MÉDICO",
  },
  {
    value: 257,
    label: "HOSPITALES GENERALES",
  },
  {
    value: 258,
    label: "HOSPITALES PSIQUIÁTRICOS Y PARA EL TRATAMIENTO POR ADICCIÓN",
  },
  {
    value: 259,
    label: "HOSPITALES DE OTRAS ESPECIALIDADES MÉDICAS",
  },
  {
    value: 260,
    label: "RESIDENCIAS CON CUIDADOS DE ENFERMERAS PARA ENFERMOS CONVALECIENTES, EN REHABILITACIÓN, INCURABLES Y TERMINALES",
  },
  {
    value: 261,
    label: "RESIDENCIAS PARA EL CUIDADO DE PERSONAS CON PROBLEMAS DE RETARDO MENTAL, TRASTORNO MENTAL Y ADICCIONES",
  },
  {
    value: 262,
    label: "ASILOS Y OTRAS RESIDENCIAS PARA EL CUIDADO DE ANCIANOS",
  },
  {
    value: 263,
    label: "ORFANATOS Y OTRAS RESIDENCIAS DE ASISTENCIA SOCIAL",
  },
  {
    value: 264,
    label: "SERVICIOS DE ORIENTACIÓN Y TRABAJO SOCIAL",
  },
  {
    value: 265,
    label: "SERVICIOS COMUNITARIOS DE ALIMENTACIÓN, REFUGIO Y EMERGENCIA",
  },
  {
    value: 266,
    label: "SERVICIOS DE CAPACITACIÓN PARA EL TRABAJO PARA PERSONAS DESEMPLEADAS, SUBEMPLEADAS O CON DISCAPACIDAD",
  },
  {
    value: 267,
    label: "GUARDERÍAS",
  },
  {
    value: 268,
    label: "COMPAÑÍAS Y GRUPOS DE ESPECTÁCULOS ARTÍSTICOS Y CULTURALES",
  },
  {
    value: 269,
    label: "DEPORTISTAS Y EQUIPOS DEPORTIVOS PROFESIONALES",
  },
  {
    value: 270,
    label: "PROMOTORES DE ESPECTÁCULOS ARTÍSTICOS, CULTURALES, DEPORTIVOS Y SIMILARES",
  },
  {
    value: 271,
    label: "AGENTES Y REPRESENTANTES DE ARTISTAS, DEPORTISTAS Y SIMILARES",
  },
  {
    value: 272,
    label: "ARTISTAS, ESCRITORES Y TÉCNICOS INDEPENDIENTES",
  },
  {
    value: 273,
    label: "MUSEOS, SITIOS HISTÓRICOS, ZOOLÓGICOS Y SIMILARES",
  },
  {
    value: 274,
    label: "PARQUES CON INSTALACIONES RECREATIVAS Y CASAS DE JUEGOS ELECTRÓNICOS",
  },
  {
    value: 275,
    label: "CASINOS, LOTERÍAS Y OTROS JUEGOS DE AZAR",
  },
  {
    value: 276,
    label: "OTROS SERVICIOS RECREATIVOS",
  },
  {
    value: 277,
    label: "HOTELES, MOTELES Y SIMILARES",
  },
  {
    value: 278,
    label: "CAMPAMENTOS Y ALBERGUES RECREATIVOS",
  },
  {
    value: 279,
    label: "PENSIONES Y CASAS DE HUÉSPEDES, Y DEPARTAMENTOS Y CASAS AMUEBLADOS CON SERVICIOS DE HOTELERÍA",
  },
  {
    value: 280,
    label: "SERVICIOS DE PREPARACIÓN DE ALIMENTOS POR ENCARGO",
  },
  {
    value: 281,
    label: "CENTROS NOCTURNOS, BARES, CANTINAS Y SIMILARES",
  },
  {
    value: 282,
    label: "SERVICIOS DE PREPARACIÓN DE ALIMENTOS Y BEBIDAS ALCOHÓLICAS Y NO ALCOHÓLICAS",
  },
  {
    value: 283,
    label: "REPARACIÓN Y MANTENIMIENTO DE AUTOMÓVILES Y CAMIONES",
  },
  {
    value: 284,
    label: "REPARACIÓN Y MANTENIMIENTO DE EQUIPO ELECTRÓNICO Y DE EQUIPO DE PRECISIÓN",
  },
  {
    value: 285,
    label: "REPARACIÓN Y MANTENIMIENTO DE MAQUINARIA Y EQUIPO AGROPECUARIO, INDUSTRIAL, COMERCIAL Y DE SERVICIOS",
  },
  {
    value: 286,
    label: "REPARACIÓN Y MANTENIMIENTO DE ARTÍCULOS PARA EL HOGAR Y PERSONALES",
  },
  {
    value: 287,
    label: "SALONES Y CLÍNICAS DE BELLEZA, BAÑOS PÚBLICOS Y BOLERÍAS",
  },
  {
    value: 288,
    label: "LAVANDERÍAS Y TINTORERÍAS",
  },
  {
    value: 289,
    label: "SERVICIOS FUNERARIOS Y ADMINISTRACIÓN DE CEMENTERIOS",
  },
  {
    value: 290,
    label: "ESTACIONAMIENTOS Y PENSIONES PARA VEHÍCULOS AUTOMOTORES",
  },
  {
    value: 291,
    label: "SERVICIOS DE REVELADO E IMPRESIÓN DE FOTOGRAFÍAS Y OTROS SERVICIOS PERSONALES",
  },
  {
    value: 292,
    label: "ASOCIACIONES Y ORGANIZACIONES COMERCIALES, LABORALES, PROFESIONALES Y RECREATIVAS",
  },
  {
    value: 293,
    label: "ASOCIACIONES Y ORGANIZACIONES RELIGIOSAS, POLÍTICAS Y CIVILES",
  },
  {
    value: 294,
    label: "HOGARES CON EMPLEADOS DOMÉSTICOS",
  },
  {
    value: 295,
    label: "ÓRGANOS LEGISLATIVOS",
  },
  {
    value: 296,
    label: "ADMINISTRACIÓN PÚBLICA EN GENERAL",
  },
  {
    value: 297,
    label: "REGULACIÓN Y FOMENTO DEL DESARROLLO ECONÓMICO",
  },
  {
    value: 298,
    label: "IMPARTICIÓN DE JUSTICIA Y MANTENIMIENTO DE LA SEGURIDAD Y EL ORDEN PÚBLICO",
  },
  {
    value: 299,
    label: "REGULACIÓN Y FOMENTO DE ACTIVIDADES PARA MEJORAR Y PRESERVAR EL MEDIO AMBIENTE",
  },
  {
    value: 300,
    label: "ACTIVIDADES ADMINISTRATIVAS DE INSTITUCIONES DE BIENESTAR SOCIAL",
  },
  {
    value: 301,
    label: "RELACIONES EXTERIORES",
  },
  {
    value: 302,
    label: "ACTIVIDADES DE SEGURIDAD NACIONAL",
  },
  {
    value: 303,
    label: "ORGANISMOS INTERNACIONALES Y EXTRATERRITORIALES",
  },
  {
    value: 304,
    label: "ALUMBRADO PÚBLICO",
  },
  {
    value: 305,
    label: null,
  },
  {
    value: 306,
    label: "PROVISIONAL PARA CONSTRUCCIÓN DE OBRA",
  },
  {
    value: 307,
    label: "BOMBEO",
  },
  {
    value: 308,
    label: "ÁREAS COMUNES DE EDIFICIOS",
  },
  {
    value: 309,
    label: "CASA HABITACIÓN",
  },
  {
    value: 310,
    label: null,
  },
  {
    value: 311,
    label: null,
  },
  {
    value: 312,
    label: "OFICINAS",
  },
  {
    value: 313,
    label: "RECOLECCIÓN DE RESIDUOS",
  },
  {
    value: 314,
    label: "CONDOMINIOS HORIZONTALES O EDIFICIOS HABITACIONALES DE MAS DE TRES NIVELES",
  },
  {
    value: 315,
    label: "CONSULTORIOS DE MÁS DE TRES NIVELES",
  },
  {
    value: 316,
    label: "EDIFICIOS DE MÁS DE TRES NIVELES",
  },
  {
    value: 317,
    label: "FONDOS DE INVERSIÓN",
  },
  {
    value: 318,
    label: "POZOS DE RIEGO",
  },
  {
    value: 319,
    label: "PUNTO DE CARGA PARA AUTOMÓVIL ELÉCTRICO",
  },
  {
    value: 320,
    label: "SOCIEDADES DE INVERSIÓN ESPECIALIZADAS EN FONDOS PARA EL RETIRO",
  },
  {
    value: 321,
    label: "TEMPLOS RELIGIOSOS",
  },
  {
    value: 322,
    label: "TRATAMIENTO Y DISPOSICIÓN FINAL DE RESIDUOS",
  },
];
const tiposconcentracion = [
  {
    value: 1,
    label: "ALBERCAS PUBLICAS Y BALNEARIOS",
  },
  {
    value: 2,
    label: "ALBERGUES",
  },
  {
    value: 3,
    label:
      "CONDOMINIOS HORIZONTALES O EDIFICIOS HABITACIONALES DE MÁS DE TRES NIVELES, EXCLUSIVAMENTE EN LAS INSTALACIONES DE LOS SERVICIOS COMUNES Y ALIMENTACIÓN GENERAL",
  },
  {
    value: 4,
    label: "ARENAS DE BOX Y LUCHAS",
  },
  {
    value: 5,
    label: "ASILOS",
  },
  {
    value: 6,
    label: "AUDITORIOS",
  },
  {
    value: 7,
    label: "BANCOS, INSTITUCIONES FINANCIERAS",
  },
  {
    value: 8,
    label: "BAÑOS PUBLICOS",
  },
  {
    value: 9,
    label: "BARES Y CANTINAS",
  },
  {
    value: 10,
    label: "BIBLIOTECAS PUBLICAS",
  },
  {
    value: 11,
    label: "BOLICHES Y BILLARES",
  },
  {
    value: 12,
    label: "CAJAS DE AHORRO Y CAJAS DE EMPEÑO",
  },
  {
    value: 13,
    label: "CÁRCELES Y RECLUSORIOS",
  },
  {
    value: 14,
    label: "CARPAS Y CIRCOS",
  },
  {
    value: 15,
    label: "CENTROS DE CONVENCIONES Y DE CONFERENCIAS",
  },
  {
    value: 16,
    label: "CENTROS DEPORTIVOS",
  },
  {
    value: 17,
    label: "CENTROS NOCTURNOS Y CABARETS",
  },
  {
    value: 18,
    label: "CENTROS Y PLAZAS COMERCIALES EN SUS AREAS COMUNES",
  },
  {
    value: 19,
    label: "CINES",
  },
  {
    value: 20,
    label: "COMERCIOS E INDUSTRIAS DE CUALQUIER TIPO",
  },
  {
    value: 21,
    label: "CORTIJOS",
  },
  {
    value: 22,
    label: null,
  },
  {
    value: 23,
    label: "CUARTELES",
  },
  {
    value: 24,
    label: "EDIFICIOS P/OFICINAS O CONSULTORIOS + 3 NIVELES",
  },
  {
    value: 25,
    label: "EDIFICIOS PARA OFICINAS PUBLICAS",
  },
  {
    value: 26,
    label: "ESCUELAS Y DEMAS CENTROS DOCENTES",
  },
  {
    value: 27,
    label: "ESTADIOS",
  },
  {
    value: 28,
    label: "ESTÉTICAS, PELUQUERIAS Y SALONES DE BELLEZA",
  },
  {
    value: 29,
    label: "FERIAS Y CENTROS DE EXPOSICIONES",
  },
  {
    value: 30,
    label: "FUNERARIAS",
  },
  {
    value: 31,
    label: "GALERIAS O SALAS DE EXPOSICION",
  },
  {
    value: 32,
    label: "GIMNASIOS",
  },
  {
    value: 33,
    label: "GUARDERIAS Y ESTANCIAS INFANTILES",
  },
  {
    value: 34,
    label: "HOSPITALES, CLINICAS Y SANATORIOS",
  },
  {
    value: 35,
    label: "HOTELES Y MOTELES",
  },
  {
    value: 36,
    label: null,
  },
  {
    value: 37,
    label: "JUEGOS MECANICOS",
  },
  {
    value: 38,
    label: "LABORATORIOS",
  },
  {
    value: 39,
    label: "LAVADO Y ENGRASADO DE VEHICULOS AUTOMOTORES",
  },
  {
    value: 40,
    label: "LAVANDERIAS Y TINTORERIAS",
  },
  {
    value: 41,
    label: "LIENZOS CHARROS",
  },
  {
    value: 42,
    label: "LOS LUGARES CON SUMINISTROS DE MÁS DE 1000 VOLTS ENTRE CONDUCTORES, O MÁS DE 600 VOLTS CON RESPECTO A TIERRA",
  },
  {
    value: 43,
    label: "MERCADOS",
  },
  {
    value: 44,
    label: "MUSEOS",
  },
  {
    value: 45,
    label: "PARQUES DE DIVERSIONES",
  },
  {
    value: 46,
    label: "PISTAS DE PATINAJE SOBRE HIELO",
  },
  {
    value: 47,
    label: "PLAZAS TAURINAS",
  },
  {
    value: 48,
    label: "QUIOSCOS EN CENTROS COMERCIALES",
  },
  {
    value: 49,
    label: "RESTAURANTES, CAFETERIAS Y COMEDORES",
  },
  {
    value: 50,
    label: "SALAS DE APUESTAS, JUEGOS Y CASINOS",
  },
  {
    value: 51,
    label: "SALAS DE EXHIBICION Y VENTAS",
  },
  {
    value: 52,
    label: "SALAS DE VIDEOJUEGO",
  },
  {
    value: 53,
    label: "SALAS PARA FIESTAS",
  },
  {
    value: 54,
    label: "SALONES PARA BAILE Y DISCOTECAS",
  },
  {
    value: 55,
    label: "TALLERES DE COSTURA",
  },
  {
    value: 56,
    label: "TALLERES MECANICOS",
  },
  {
    value: 57,
    label: "TEATROS Y CINES",
  },
  {
    value: 58,
    label: "TEMPLOS RELIGIOSOS",
  },
  {
    value: 59,
    label: "TERMINALES PARA PASAJEROS",
  },
  {
    value: 60,
    label: "TIENDAS DE CONVENIENCIA",
  },
  {
    value: 61,
    label: "TORTILLERIAS Y MOLINOS DE NIXTAMAL",
  },
  {
    value: 62,
    label: null,
  },
  {
    value: 63,
    label: null,
  },
  {
    value: 64,
    label: null,
  },
  {
    value: 65,
    label: "CENTROS DE ATENCIÓN A CLIENTES Y CENTROS DE ATENCIÓN TELEFÓNICA",
  },
  {
    value: 66,
    label: null,
  },
  {
    value: 67,
    label: null,
  },
  {
    value: 68,
    label: null,
  },
  {
    value: 69,
    label: "LOCALES UBICADOS EN EL ÁREA DE COMIDA RÁPIDA",
  },
  {
    value: 70,
    label: "Venta de solventes envasados o a granel",
  },
];
const tiposdato = [
  {
    value: 1,
    label: "CURP",
  },
  {
    value: 2,
    label: "CLAVE IFE",
  },
  {
    value: 3,
    label: "FOLIO IFE",
  },
  {
    value: 4,
    label: "DIRECCION IFE",
  },
  {
    value: 5,
    label: "CLAVE INE",
  },
  {
    value: 6,
    label: "FOLIO INE",
  },
  {
    value: 7,
    label: "DIRECCION INE",
  },
  {
    value: 8,
    label: "CARTILLA MILITAR",
  },
  {
    value: 9,
    label: "PASAPORTE",
  },
  {
    value: 10,
    label: "SRE",
  },
  {
    value: 11,
    label: "RFC",
  },
  {
    value: 12,
    label: "REVERSO INE",
  },
];
const tiposinstalacion = [
  {
    value: 1,
    label: "II. Áreas Peligrosas (Clasificadas)",
  },
  {
    value: 2,
    label: "IV. Tipos I y II - (Lugar de Concentración Pública y Áreas Peligrosas (Clasificadas))",
  },
  {
    value: 3,
    label: "III. Industria",
  },
  {
    value: 4,
    label: "I. Lugar de Concentración Pública",
  },
  {
    value: 5,
    label: "Otros - (Otro tipo)",
  },
  {
    value: 6,
    label: "V. Tipos I y III - (Lugar de Concentración Pública e Industria)",
  },
  {
    value: 7,
    label: "VI. Tipos II y III - (Áreas Peligrosas (Clasificadas) e Industria)",
  },
  {
    value: 8,
    label: "VII. Tipos I, II y III - (Lugar de Concentración Pública, Áreas Peligrosas (Clasificadas) e Industria)",
  },
];
const tipospersona = [
  {
    value: 1,
    label: "Física",
  },
  {
    value: 2,
    label: "Moral",
  },
];
const tipostelefono = [
  {
    value: 1,
    label: "Móvil",
  },
  {
    value: 2,
    label: "Fijo",
  },
];
const tiposverificacion = [
  {
    value: 1,
    label: "Instalación nueva",
  },
  {
    value: 2,
    label: "Ampliación de una Instalación",
  },
  {
    value: 3,
    label: "Modificación de una instalación",
  },
  {
    value: 4,
    label: "Verificación periódica de una instalación con áreas peligrosas (clasificadas)",
  },
  {
    value: 5,
    label: "Instalación Construída antes de la entrada en Vigor de la NOM-001-SEDE-2012",
  },
  {
    value: 6,
    label: "Subestación para cambio de tensión para la alimentación de la instalación",
  },
];

const listasInspeccionFijas = [
  { value: 1, label: "ALMACEN" },
  { value: 2, label: "ALMACEN CON TRANSFORMADOR" },
  { value: 3, label: "ALUMBRADO" },
  { value: 4, label: "ALUMBRADO CON TRANSFORMADOR" },
  { value: 5, label: "ANEXO B" },
  { value: 6, label: "ANEXO B CON TRANSFORMADOR" },
  { value: 7, label: "BOMBA" },
  { value: 8, label: "CASA HABITACION CON TRANSFORMADOR Y AIRE ACONDICIONADO" },
  { value: 9, label: "EDIFICIO DE OFICINAS" },
  { value: 10, label: "ESCUELAS" },
  { value: 11, label: "ESCUELA CON TRANSFORMADOR" },
  { value: 12, label: "FEDEX (MENOS DE 100 kW)" },
  { value: 13, label: "GASERA BAJA TENSION" },
  { value: 14, label: "GENERAL" },
  { value: 15, label: "PROVISIONAL" },
  { value: 16, label: "PROVISIONAL CON TRANSFORMADOR" },
  { value: 17, label: "SISTEMA FOTOVOLTAICO" },
  { value: 18, label: "TIENDA DE CONVENIENCIA" },
  { value: 19, label: "TIENDA DE CONVENIENCIA CON TRANSFORMADOR" },
  { value: 20, label: "TIENDA DE ROPA" },
];

const listasInspeccionVariables = [
  { value: 1, label: "GENERALES" },
  { value: 2, label: "CONTACTOS" },
  { value: 3, label: "AIRE ACONDICIONADO" },
  { value: 4, label: "CONDUCTORES EN PARALELO" },
  { value: 5, label: "CHAROLA" },
  { value: 6, label: "LUMINARIAS" },
  { value: 7, label: "MOTORES" },
  { value: 8, label: "TRANSFORMADOR" },
];

//export
export {
  nacionalidades,
  scian,
  tiposconcentracion,
  tiposdato,
  tiposinstalacion,
  tipospersona,
  tipostelefono,
  tiposverificacion,
  listasInspeccionFijas,
  listasInspeccionVariables,
};
