import { useState } from "react";
import { Card, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import GeneralForm from "./forms/general/GeneralForm";
import FechasForm from "./forms/general/FechasForm";
import NotasForm from "./forms/general/NotasForm";
import ClasificacionForm from "./forms/instalacion/ClasificacionForm";
import ValoresForm from "./forms/instalacion/ValoresForm";
import TransformadorForm from "./forms/instalacion/TransformadorForm";
import PVInfoForm from "./forms/razonsocial/PVInfoForm";
import PVDomicilioForm from "./forms/razonsocial/PVDomicilioForm";
import PFInfoForm from "./forms/involucrados/PFInfoForm";
import PAInfoForm from "./forms/involucrados/PAInfoForm";
import PSInfoForm from "./forms/involucrados/PSInfoForm";
import DesarrolloForm from "./forms/visita/DesarrolloForm";
import PruebasForm from "./forms/visita/PruebasForm";
import TestigoForm from "./forms/visita/TestigoForm";

export default function DataGroup(props) {
  const { title, subtitle, formName, mainData, setMainData, verification } = props;
  const [visible, setVisible] = useState(false);

  const commonFormProps = {
    mainData,
    setMainData,
    setVisible,
    visible,
    title: formName,
  };

  return (
    <>
      <Card size="small" bordered={false} style={{ height: "100%", boxSizing: "border-box" }}>
        <span className="name-preview">{title}</span>
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={{ float: "right" }}
          size="small"
          onClick={() => {
            setVisible(true);
          }}
        />
        <br />
        <span className="name-preview-subtitle">{subtitle}</span>
        <div className="data-group">{props.children}</div>
      </Card>
      {(() => {
        switch (formName) {
          case "Información general":
            return <GeneralForm {...commonFormProps} />;
          case "Fechas":
            return <FechasForm {...commonFormProps} />;
          case "Notas":
            return <NotasForm {...commonFormProps} />;
          case "Información de la Razón social":
            return <PVInfoForm {...commonFormProps} />;
          case "Domicilio de la Razón social":
            return <PVDomicilioForm {...commonFormProps} />;
          case "Persona que firma el contrato":
            return <PFInfoForm {...commonFormProps} />;
          case "Persona que atiende la visita":
            return <PAInfoForm {...commonFormProps} />;
          case "Persona que solicita la verificación":
            return <PSInfoForm {...commonFormProps} />;
          case "Clasificación de la verificación":
            return <ClasificacionForm {...commonFormProps} />;
          case "Valores de la instalación eléctrica":
            return <ValoresForm {...commonFormProps} />;
          case "Información del Transformador":
            return <TransformadorForm {...commonFormProps} />;
          case "Desarrollo de la visita":
            return <DesarrolloForm {...commonFormProps} />;
          case "Pruebas":
            return <PruebasForm {...commonFormProps} verification={verification} />;
          case "Testigo 1":
            return <TestigoForm {...commonFormProps} />;
          case "Testigo 2":
            return <TestigoForm {...commonFormProps} />;
          default:
            return null;
        }
      })()}
    </>
  );
}
