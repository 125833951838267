import { Form, Input, Select, Button, message, Row, Col, Space, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";
import { tiposconcentracion, tiposinstalacion, tiposverificacion } from "../formOptions";

export default function ClasificacionForm(props) {
  const { title, visible, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [otraInstalacionVisible, setOtraInstalacionVisible] = useState(false);
  const [concentracionPublicaVisible, setConcentracionPublicaVisible] = useState(false);
  const [superficieDpeaVisible, setSuperficieDpeaVisible] = useState(false);
  const [tipoVialidadVisible, setTipoVialidadVisible] = useState(false);
  const otraInstalacionRef = useRef();
  const concentracionPublicaRef = useRef();
  const superficieDpeaRef = useRef();
  const tipoVialidadRef = useRef();
  const [form] = Form.useForm();

  const colProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  useEffect(() => {
    form.setFieldsValue(verification);
  }, [visible, verification, form]);

  useEffect(() => {
    const value = verification?.TipoInstalacionID;
    if (value === 2 || value === 4 || value === 6 || value === 8) {
      setConcentracionPublicaVisible(true);
      setOtraInstalacionVisible(false);
    } else if (value === 5) {
      setConcentracionPublicaVisible(false);
      setOtraInstalacionVisible(true);
    } else {
      setConcentracionPublicaVisible(false);
      setOtraInstalacionVisible(false);
    }
  }, [verification?.TipoInstalacionID]);

  useEffect(() => {
    if (verification?.Nom007 === "Sí" && verification?.Nom013 === "Sí") {
      setSuperficieDpeaVisible(true);
      setTipoVialidadVisible(true);
    } else if (verification?.Nom007 === "Sí" && verification?.Nom013 === "No") {
      setSuperficieDpeaVisible(true);
      setTipoVialidadVisible(false);
    } else if (verification?.Nom007 === "No" && verification?.Nom013 === "Sí") {
      setSuperficieDpeaVisible(true);
      setTipoVialidadVisible(true);
    } else {
      setSuperficieDpeaVisible(false);
      setTipoVialidadVisible(false);
    }
  }, [verification?.Nom007, verification?.Nom013]);

  useEffect(() => {
    if (otraInstalacionVisible) {
      otraInstalacionRef?.current?.focus();
    } else if (concentracionPublicaVisible) {
      concentracionPublicaRef?.current?.focus();
    }
  }, [otraInstalacionVisible, concentracionPublicaVisible]);

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical" initialValues={verification}>
          <AnimateItems delay={0.15}>
            <Row gutter={[40, 0]}>
              <Col {...colProps}>
                <Form.Item name="TipoVerificacionID" label="Tipo de verificación">
                  <Select {...clearSelectProps}>
                    {tiposverificacion.map((tipo) => (
                      <Select.Option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="TipoInstalacionID" label="Tipo de instalación">
                  <Select
                    onChange={(value) => {
                      if (value === 2 || value === 4 || value === 6 || value === 8) {
                        setConcentracionPublicaVisible(true);
                        setOtraInstalacionVisible(false);
                      } else if (value === 5) {
                        setConcentracionPublicaVisible(false);
                        setOtraInstalacionVisible(true);
                      } else {
                        setConcentracionPublicaVisible(false);
                        setOtraInstalacionVisible(false);
                      }
                    }}
                    {...clearSelectProps}
                  >
                    {tiposinstalacion.map((tipo) => (
                      <Select.Option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="TipoInstalacionOtro" label="Otro tipo de instalación" hidden={!otraInstalacionVisible}>
                  <Input ref={otraInstalacionRef} placeholder="Especifique otro tipo de instalación" />
                </Form.Item>
                <Form.Item name="TipoConcentracionID" label="Tipo de concentración" hidden={!concentracionPublicaVisible}>
                  <Select {...clearSelectProps} ref={concentracionPublicaRef}>
                    {tiposconcentracion.map((tipo) => (
                      <Select.Option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item name="Nom007" label="Aplica NOM 007">
                  <Select
                    {...clearSelectProps}
                    onChange={(value) => {
                      if (value === "Sí") {
                        setSuperficieDpeaVisible(true);
                      } else {
                        setSuperficieDpeaVisible(false);
                      }
                    }}
                  >
                    <Select.Option value="Sí">Sí</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="Nom013" label="Aplica NOM 013">
                  <Select
                    {...clearSelectProps}
                    onChange={(value) => {
                      if (value === "Sí") {
                        setSuperficieDpeaVisible(true);
                        setTipoVialidadVisible(true);
                      } else {
                        setSuperficieDpeaVisible(false);
                        setTipoVialidadVisible(false);
                      }
                    }}
                  >
                    <Select.Option value="Sí">Sí</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col {...colProps}>
                    <Form.Item name="SuperficieDPEA" label="Superficie para DPEA" hidden={!superficieDpeaVisible}>
                      <Input placeholder="Superficie para DPEA" ref={superficieDpeaRef} />
                    </Form.Item>
                  </Col>
                  <Col {...colProps}>
                    <Form.Item name="TipoVialidad" label="Tipo de vialidad" hidden={!tipoVialidadVisible}>
                      <Select {...clearSelectProps} placeholder="Seleccione el tipo de vialidad" ref={tipoVialidadRef}>
                        <Select.Option value="Vialidad">Vialidad</Select.Option>
                        <Select.Option value="Estacionamiento">Estacionamiento</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </AnimateItems>
        </Form>
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                const verificationData = { ...verification, ...form.getFieldsValue() };
                axios
                  .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification?.VerificacionID}`, verificationData)
                  .then(() => {
                    setVisible(false);
                    axios.get(`${process.env.REACT_APP_API_URL}/verificaciones/${verification?.VerificacionID}`).then((res) => {
                      setVerification(res.data[0]);
                      message.success("Campos actualizados correctamente");
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error("Error al actualizar los campos");
                  });
              }}
            >
              Guardar
            </Button>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </Button>
          </Space>
        </div>
      </Modal>
    </>
  );
}
