import { Collapse, Button, Row, Col } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import DataGroup from "./DataGroup";
import DataItem from "./DataItem";
import Subtitle from "./Subtitle";
import AddVisitForm from "./forms/general/AddVisitForm";
import moment from "moment";
import "moment/locale/es";
moment.locale("es-mx");

export default function VisitPreview(props) {
  const { id, verification } = props;
  const [AddVisitFormVisible, setAddVisitFormVisible] = useState(false);
  const [visits, setVisits] = useState();
  const [tipo, setTipo] = useState();

  useEffect(() => {
    if (verification) {
      if (verification.TipoVerificacionID === 5 && verification.Capacidad === 0) {
        setTipo("vieja");
      } else if (verification.TipoVerificacionID === 5 && verification.Capacidad > 0) {
        setTipo("viejatro");
      } else if (verification.AlcanceVerificacion < 100) {
        setTipo("menor100");
      } else if (verification.AlcanceVerificacion > 100) {
        setTipo("mayor100");
      }
    }
  }, [verification]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/visitas/${id}`)
      .then((res) => {
        console.table(res.data[0]);
        setVisits(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => {
              setAddVisitFormVisible(true);
            }}
          >
            Agregar visita
          </Button>
        </Col>
      </Row>
      <Collapse bordered={false} accordion>
        {visits &&
          visits.map((visit, index) => (
            <Collapse.Panel
              key={visit.VisitaID}
              header={
                <div>
                  <span style={{ fontWeight: "bold" }}>{`Visita ${index + 1} `}</span>
                  <span style={{ color: "darkgray" }}>
                    {visit.FechaVisita ? `- ${moment(visit.FechaVisita).utc().format("LL")}` : "Fecha indefinida "}
                    {visit.HoraInicio ? `- De ${moment(visit.HoraInicio, "HH:mm").format("HH:mm")}` : " - De hora de inicio indefinida"}
                    {visit.HoraTermino ? ` a ${moment(visit.HoraTermino, "HH:mm").format("HH:mm")}` : " a hora de término indefinida"}
                  </span>
                </div>
              }
            >
              <Subtitle title="Desarrollo de la visita" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <DataGroup mainData={visit} setMainData={setVisits} formName={"Desarrollo de la visita"}>
                    <DataItem
                      keyName="Lista de inspección"
                      value={visit.ListaInspeccion}
                      valueType="longString"
                      fieldWidth={6}
                      button={`Descargar Lista ${visit.ListaTipo}`}
                      buttonFunction={() => {
                        axios({
                          method: "get",
                          url:
                            visit.ListaTipo === "Fija"
                              ? `${process.env.REACT_APP_API_URL}/formatos/listainspeccion/fija/${visit.ListaInspeccion}/${visit.VisitaID}`
                              : `${process.env.REACT_APP_API_URL}/formatos/listainspeccion/variable/${tipo}/${visit.ListaInspeccion}/${visit.VisitaID}`,
                          responseType: "blob",
                        })
                          .then((res) => {
                            const fileName = `Lista de inspección ${new Date().getTime()}.docx`;
                            const blob = new Blob([res.data], {
                              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            });
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                    <DataItem keyName="Objeto de la visita" value={visit.ObjetoVisita} valueType="longString" />
                    <DataItem keyName="Circunstancia de la visita" value={visit.CircunstanciaVisita} valueType="longString" />
                    <DataItem keyName="No conformidades del encargado" value={visit.NoConformidadesEncargado} valueType="longString" />
                    <DataItem keyName="Observaciones del encargado" value={visit.ObservacionesEncargado} valueType="longString" />
                    <DataItem keyName="Acciones" value={visit.Acciones} valueType="longString" fieldWidth={4} />
                  </DataGroup>
                </Col>
              </Row>
              <Subtitle title="Pruebas realizadas" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <DataGroup mainData={visit} setMainData={setVisits} formName="Pruebas" verification={verification}>
                    <DataItem
                      keyName="Resultado de la prueba de Resistencia de aislamiento"
                      value={visit.ResResistenciaAislamiento}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Equipo para la prueba de Resistencia de aislamiento"
                      value={visit.EqResistenciaAislamiento}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Resultado de prueba de Resistencia de las tierras físicas"
                      value={visit.ResResistenciaTierraFisica}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Equipo para la prueba de Resistencia de las tierras físicas"
                      value={visit.EqResistenciaTierraFisica}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Resultado de la prueba de Continuidad eléctrica"
                      value={visit.ResContinuidadElectrica}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Equipo para la prueba de Continuidad eléctrica"
                      value={visit.EqContinuidadElectrica}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Resultado de la prueba de Polaridad de los contactos"
                      value={visit.ResPolaridadContactos}
                      valueType="longString"
                      fieldWidth={4}
                    />
                    <DataItem
                      keyName="Equipo para la prueba de Polaridad de los contactos"
                      value={visit.EqPolaridadContactos}
                      valueType="longString"
                      fieldWidth={4}
                    />
                  </DataGroup>
                </Col>
              </Row>
              <Subtitle title="Testigos" marginTop={16} />
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={8}>
                  <DataGroup
                    title={!visit?.NombreCompletoPT1 ? "TESTIGO 1" : `TESTIGO 1: ${visit?.NombreCompletoPT1}`}
                    subtitle={
                      visit?.TipoPersonaPT1 && visit?.NacionalidadPT1
                        ? "Persona " + visit?.TipoPersonaPT1 + ", " + visit?.NacionalidadPT1
                        : null
                    }
                    mainData={visit}
                    setMainData={setVisits}
                    formName="Testigo 1"
                  >
                    <DataItem
                      keyName={visit.TipoDatoPT1 ? visit.TipoDatoPT1 : "Tipo de identificación"}
                      value={visit.DatoPT1_T}
                      valueType="string"
                      fieldWidth={4}
                    />
                    <DataItem keyName="Domicilio" value={visit.DomicilioPT1_T} valueType="string" fieldWidth={4} />
                  </DataGroup>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={8}>
                  <DataGroup
                    title={!visit?.NombreCompletoPT2 ? "TESTIGO 2" : `TESTIGO 1: ${visit?.NombreCompletoPT2}`}
                    subtitle={
                      visit?.TipoPersonaPT2 && visit?.NacionalidadPT2
                        ? "Persona " + visit?.TipoPersonaPT2 + ", " + visit?.NacionalidadPT2
                        : null
                    }
                    mainData={visit}
                    setMainData={setVisits}
                    formName="Testigo 2"
                  >
                    <DataItem
                      keyName={visit.TipoDatoPT2 ? visit.TipoDatoPT2 : "Tipo de identificación"}
                      value={visit.DatoPT2_T}
                      valueType="string"
                      fieldWidth={4}
                    />
                    <DataItem keyName="Domicilio" value={visit.DomicilioPT2_T} valueType="string" fieldWidth={4} />
                  </DataGroup>
                </Col>
              </Row>
            </Collapse.Panel>
          ))}
      </Collapse>
      <AddVisitForm id={id} setVisits={setVisits} visible={AddVisitFormVisible} setVisible={setAddVisitFormVisible} />
    </>
  );
}
