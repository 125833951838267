import { Form, Select, Modal, Button, message, Divider, Space } from "antd";
import { CloseOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";
import PersonaForm from "../persona/PersonaForm";

export default function TestigoForm(props) {
  const { title, visible, mainData: visita, setMainData: setVisita, setVisible } = props;
  const [personaFormVisible, setPersonaFormVisible] = useState(false);
  const [foliosPersona, setFoliosPersona] = useState();
  const [domiciliosPersona, setDomiciliosPersona] = useState();
  const [personasList, setPersonasList] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [personaEditing, setPersonaEditing] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    visible &&
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/personas`),
        axios.get(
          `${process.env.REACT_APP_API_URL}/datos/folios/${
            title === "Testigo 1" ? visita?.PT1 : title === "Testigo 2" ? visita?.PT2 : null
          }`
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL}/datos/domicilios/${
            title === "Testigo 1" ? visita?.PT1 : title === "Testigo 2" ? visita?.PT2 : null
          }`
        ),
      ])
        .then(([p, f, d]) => {
          setPersonasList(p.data.filter((persona) => persona.TipoPersonaID === 1));
          setFoliosPersona(f.data);
          setDomiciliosPersona(d.data);
          form.setFieldsValue(visita);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
  }, [visita, form, visible, title]);

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  return (
    <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} centered>
      <Form form={form} layout="vertical">
        <AnimateItems delay={0.15}>
          <Form.Item name={title === "Testigo 1" ? "PT1" : title === "Testigo 2" ? "PT2" : null} label="Testigo">
            <Select
              onChange={(value) => {
                if (value) {
                  Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/datos/folios/${value}`),
                    axios.get(`${process.env.REACT_APP_API_URL}/datos/domicilios/${value}`),
                  ])
                    .then(([d, t, c]) => {
                      setFoliosPersona(d.data);
                      setDomiciliosPersona(t.data);
                      if (title === "Testigo 1") {
                        form.setFieldsValue({
                          DatoPT1: d.data[0].DatoID,
                          DomicilioPT1: d.data[0].DatoID,
                        });
                      } else if (title === "Testigo 2") {
                        form.setFieldsValue({
                          DatoPT2: d.data[0].DatoID,
                          DomicilioPT2: d.data[0].DatoID,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      message.error("Error al cargar los datos");
                    });
                } else if (!value) {
                  setFoliosPersona(null);
                  setDomiciliosPersona(null);
                  if (title === "Testigo 1") {
                    form.setFieldsValue({
                      DatoPT1: null,
                      DomicilioPT1: null,
                    });
                  } else if (title === "Testigo 2") {
                    form.setFieldsValue({
                      DatoPT2: null,
                      DomicilioPT2: null,
                    });
                  }
                }
              }}
              {...clearSelectProps}
              open={openDropdown}
              onDropdownVisibleChange={(visibility) => setOpenDropdown(visibility)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div style={{ float: "right" }}>
                    <Divider style={{ margin: "4px 0" }} />
                    <Space direction="horizontal">
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                          setPersonaEditing(form.getFieldValue(title === "Testigo 1" ? "PT1" : title === "Testigo 2" ? "PT2" : null));
                          setOpenDropdown(false);
                          setTimeout(() => {
                            setPersonaFormVisible(true);
                          }, 250);
                        }}
                      >
                        Editar persona
                      </Button>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setPersonaEditing(null);
                          setOpenDropdown(false);
                          setTimeout(() => {
                            setPersonaFormVisible(true);
                          }, 250);
                        }}
                      >
                        Agregar persona
                      </Button>
                    </Space>
                  </div>
                </>
              )}
            >
              {personasList?.map((persona) => (
                <Select.Option key={persona.PersonaID} value={persona.PersonaID}>
                  {persona.NombreCompleto}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={title === "Testigo 1" ? "DatoPT1" : title === "Testigo 2" ? "DatoPT2" : null}
            label="Folio de identificación del testigo"
          >
            <Select {...clearSelectProps}>
              {foliosPersona?.map((folio) => (
                <Select.Option key={folio.DatoID} value={folio.DatoID}>
                  {folio.TipoDato_Corto + ": " + folio.Dato}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={title === "Testigo 1" ? "DomicilioPT1" : title === "Testigo 2" ? "DomicilioPT2" : null}
            label="Domicilio del testigo"
          >
            <Select {...clearSelectProps}>
              {domiciliosPersona?.map((domicilio) => (
                <Select.Option key={domicilio.DatoID} value={domicilio.DatoID}>
                  {domicilio.TipoDato_Corto + ": " + domicilio.Dato}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </AnimateItems>
      </Form>
      <PersonaForm
        setPersonasList={setPersonasList}
        setFoliosPersona={setFoliosPersona}
        setDomiciliosPersona={setDomiciliosPersona}
        personaEditing={personaEditing}
        personaFormVisible={personaFormVisible}
        setPersonaFormVisible={setPersonaFormVisible}
        origin="visita"
        onCancel={() => setPersonaFormVisible(false)}
      />
      <Button
        type="primary"
        onClick={() => {
          const visitData = { ...visita, ...form.getFieldsValue() };
          axios
            .put(`${process.env.REACT_APP_API_URL}/visitas/${visita.VisitaID}`, visitData)
            .then(() => {
              axios.get(`${process.env.REACT_APP_API_URL}/visitas/${visita.VerificacionID}`).then((res) => {
                setVisita(res.data);
                setVisible(false);
              });
            })
            .catch((err) => {
              console.log(err);
              message.error("Error al actualizar la visita");
            });
        }}
      >
        Guardar
      </Button>
      <Button
        onClick={() => {
          // lazy way to reset the page
          axios
            .get(`${process.env.REACT_APP_API_URL}/visitas/${visita.VerificacionID}`)
            .then((res) => {
              setVisita(res.data);
              setVisible(false);
            })
            .catch((err) => {
              console.log(err);
              message.error("Error al actualizar la visita\n" + err);
            });
        }}
      >
        Cancelar
      </Button>
    </Modal>
  );
}
