import { Form, Input, Button, message } from "antd";
import { SaveOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import axios from "axios";

export default function CorreoForm(props) {
  const { type, correo, personaEditing, setInfoCorreos, setCorreosPersona } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(correo);
  }, [form, correo]);

  const onEditSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Editar", values);
      axios
        .put(`${process.env.REACT_APP_API_URL}/correos/${correo?.CorreoElectronicoID}`, values)
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/correos/persona/${personaEditing}`)
            .then((res) => {
              setInfoCorreos(res.data);
              setCorreosPersona(res.data);
              message.success("Correo editado con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onAddSubmit = () => {
    form.validateFields().then((values) => {
      console.log("Agregar", values);
      axios
        .post(`${process.env.REACT_APP_API_URL}/correos`, {
          ...values,
          PersonaID: personaEditing,
        })
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/correos/persona/${personaEditing}`)
            .then((res) => {
              setInfoCorreos(res.data);
              setCorreosPersona(res.data);
              message.success("Correo agregado con éxito");
            })
            .catch((error) => {
              console.log(error);
              message.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    });
  };

  const onDeleteSubmit = (values) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/correos/${correo?.CorreoElectronicoID}`)
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/correos/persona/${personaEditing}`)
          .then((res) => {
            form.resetFields();
            setInfoCorreos(res.data);
            message.success("Correo eliminado con éxito");
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const clearForm = () => {
    form.resetFields();
  };

  return (
    <Form form={form}>
      <Form.Item key={correo?.CorreoID}>
        <Input.Group key={correo?.CorreoElectronicoID} compact>
          <Form.Item
            name="CorreoElectronico"
            noStyle
            rules={[{ required: true, type: "email", message: "Por favor ingrese un correo electrónico" }]}
          >
            <Input
              key={correo?.CorreoElectronicoID}
              placeholder={type === "edit" ? "Correo electrónico" : "Agregar un correo electrónico nuevo"}
              style={{ width: "60%" }}
            />
          </Form.Item>
          <Button onClick={type === "edit" ? onEditSubmit : onAddSubmit} style={{ width: "8%" }}>
            <SaveOutlined style={{ color: "green" }} />
          </Button>
          <Button onClick={type === "edit" ? onDeleteSubmit : clearForm} style={{ width: "7%" }}>
            {type === "edit" ? <DeleteOutlined style={{ color: "red" }} /> : <CloseOutlined style={{ color: "red" }} />}
          </Button>
        </Input.Group>
      </Form.Item>
    </Form>
  );
}
