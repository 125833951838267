import { Form, Input, Select, Button, InputNumber, Space, Divider, Modal, DatePicker } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import TiposModForm from "./TiposModForm";
import moment from "moment";

export default function ModForm({ visible, onCancel, setModList, record }) {
  const [form] = Form.useForm();
  const [tiposModList, setTiposModList] = useState();
  const [tiposModVisible, setTiposModVisible] = useState(false);

  const handleCloseModForm = () => {
    form.resetFields();
    onCancel();
  };

  const handleModSubmit = () => {
    record ? handleModUpdate() : handleModCreate();
  };

  const handleModUpdate = () => {
    form.validateFields().then((values) => {
      axios
        .put(`${process.env.REACT_APP_API_URL}/mods/${record.ModID}`, {
          Titulo: form.getFieldValue("Titulo"),
          Descripcion: form.getFieldValue("Descripcion"),
          Alcance: form.getFieldValue("Alcance"),
          TipoModID: form.getFieldValue("TipoModID"),
          Costo: form.getFieldValue("Costo"),
          Status: form.getFieldValue("Status"),
          FechaMod: moment(form.getFieldValue("FechaMod")).format("YYYY-MM-DD"),
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/mods`).then((res) => {
            setModList(res.data);
          });
          handleCloseModForm();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleModCreate = () => {
    form.validateFields().then((values) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/mods`, {
          Titulo: form.getFieldValue("Titulo"),
          Descripcion: form.getFieldValue("Descripcion"),
          Alcance: form.getFieldValue("Alcance"),
          TipoModID: form.getFieldValue("TipoModID"),
          Costo: form.getFieldValue("Costo"),
          Status: form.getFieldValue("Status"),
          FechaMod: moment(form.getFieldValue("FechaMod")).format("YYYY-MM-DD"),
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/mods`).then((res) => {
            setModList(res.data);
          });
          handleCloseModForm();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cat_tiposmod`)
      .then((res) => {
        setTiposModList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      Titulo: record ? record.Titulo : "",
      Descripcion: record ? record.Descripcion : "",
      Alcance: record ? record.Alcance : "",
      TipoModID: record ? record.TipoModID : "",
      Costo: record ? record.Costo : "",
      Status: record ? record.Status : "",
      FechaMod: record ? moment(record.FechaMod) : "",
    });
  }, [record, form]);

  return (
    <>
      <Modal title="Datos del mod" visible={visible} onCancel={onCancel} footer={null}>
        <Form form={form} layout="vertical" name="modForm">
          <Form.Item label="Titulo" name="Titulo" rules={[{ required: true, message: "Ingresa el título del mod" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Descripcion" name="Descripcion" rules={[{ required: true, message: "Ingresa la descripción del mod" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Alcance" name="Alcance" rules={[{ required: true, message: "Ingresa el alcance del mod" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Tipo" name="TipoModID" rules={[{ required: true, message: "Elige el tipo de mod" }]}>
            <Select
              placeholder="Selecciona un tipo de mod"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                    <Button
                      style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                      onClick={() => setTiposModVisible(true)}
                    >
                      <span>Agregar tipo</span>
                    </Button>
                  </div>
                </div>
              )}
            >
              {tiposModList?.map((tipo) => (
                <Select.Option key={tipo.TipoModID} value={tipo.TipoModID}>
                  {tipo.TipoMod}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Costo" name="Costo" rules={[{ required: true, message: "Please input the cost of the mod!" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Status" name="Status" rules={[{ required: true, message: "Please input the status of the mod!" }]}>
            <Select placeholder="Select a status">
              <Select.Option value="Revisión">Revisión</Select.Option>
              <Select.Option value="En progreso">En progreso</Select.Option>
              <Select.Option value="Completado">Completado</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Fecha de petición" name="FechaMod" rules={[{ required: true, message: "Please input the date of the mod!" }]}>
            <DatePicker />
          </Form.Item>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 16,
            }}
          >
            <Space direction="horizontal">
              <Button type="primary" onClick={handleModSubmit}>
                Agregar
              </Button>
              <Button type="primary" onClick={onCancel}>
                Cancelar
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
      <TiposModForm visible={tiposModVisible} onCancel={() => setTiposModVisible(false)} setTiposModList={setTiposModList} />
    </>
  );
}
