import { Form, Input, Typography, Button, message } from "antd";
import { useAuth } from "./auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email: email,
        password: pwd,
      })
      .then((res) => {
        auth.login(res.data);
        message.success(`Bienvenido, ${res.data.nombre}!`);
      })
      .catch((err) => {
        message.error("Usuario o contraseña incorrectos");
      });
  };

  const [form] = Form.useForm();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: "url(https://staff5.com/shutchoa/2022/05/img_a31e08b678bf864d647e59bec94b884c.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          width: "400px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Typography.Title
          level={3}
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Iniciar sesión en miuvie
        </Typography.Title>
        <Form
          form={form}
          layout="vertical"
          style={{
            width: "300px",
            margin: "0 auto",
          }}
        >
          <Form.Item
            label="Correo Electrónico"
            name="email"
            rules={[{ required: true, message: "Por favor ingrese su usuario", type: "email" }]}
          >
            <Input onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Por favor ingrese su contraseña", type: "string" }]}
          >
            <Input.Password onChange={(e) => setPwd(e.target.value)} />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" onClick={handleLogin} htmlType="submit" style={{ width: "100px", margin: "10px" }}>
              Ingresar
            </Button>
            <Button type="secondary" onClick={() => navigate("/")} style={{ width: "100px", margin: "10px" }}>
              Volver
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
