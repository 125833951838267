import { Form, Input, Button, Select, message, Modal, Divider, Space } from "antd";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import ClienteForm from "./ClienteForm";
import axios from "axios";

export default function GeneralForm(props) {
  const { visible, title, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [clientes, setClientes] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [clienteToEdit, setClienteToEdit] = useState();
  const [clienteFormVisible, setClienteFormVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes`)
      .then((res) => {
        setClientes(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al obtener los clientes");
      });
    form.setFieldsValue(verification);
  }, [verification, form]);

  const clearSelectProps = {
    style: { width: "100%" },
    allowClear: true,
    clearIcon: <CloseOutlined style={{ color: "red" }} />,
    showSearch: true,
    filterOption: (input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} centered>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.15}>
            <Form.Item name="UvieID" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="ControlInterno" label="Número de control interno">
              <Input placeholder="Escriba el número de control interno" />
            </Form.Item>
            <Form.Item name="ClienteID" label="Cliente">
              <Select
                placeholder="Seleccione el cliente"
                {...clearSelectProps}
                open={openDropdown}
                onDropdownVisibleChange={(visibility) => setOpenDropdown(visibility)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <div style={{ float: "right" }}>
                      <Divider style={{ margin: "4px 0" }} />
                      <Space direction="horizontal">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            setClienteToEdit(form.getFieldValue("ClienteID"));
                            setOpenDropdown(false);
                            setTimeout(() => {
                              setClienteFormVisible(true);
                            }, 250);
                          }}
                        >
                          Editar cliente
                        </Button>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            setClienteToEdit(null);
                            setOpenDropdown(false);
                            setTimeout(() => {
                              setClienteFormVisible(true);
                            }, 250);
                          }}
                        >
                          Agregar cliente
                        </Button>
                      </Space>
                    </div>
                  </>
                )}
              >
                {clientes &&
                  clientes.map((cliente) => (
                    <Select.Option key={cliente.ClienteID} value={cliente.ClienteID}>
                      {cliente.NombreCliente}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="DescripcionBreve" label="Descripción breve">
              <Input />
            </Form.Item>
            <Form.Item name="Monto" label="Monto">
              <Input />
            </Form.Item>
            <Form.Item name="FolioAmuvie" label="Folio de AMUVIE">
              <Input />
            </Form.Item>
          </AnimateItems>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            axios
              .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
              .then(() => {
                message.success("Campos actualizados correctamente");
                setVerification(verificationData);
                setVisible(false);
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar los campos");
              });
          }}
        >
          Guardar
        </Button>
      </Modal>
      <ClienteForm visible={clienteFormVisible} setVisible={setClienteFormVisible} cliente={clienteToEdit} setClientes={setClientes} />
    </>
  );
}
