import { Form, InputNumber, Button, message, Modal } from "antd";
import { useEffect } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";

export default function ValoresForm(props) {
  const { visible, title, mainData: verification, setMainData: setVerification, setVisible } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(verification);
  }, [verification, form]);

  const commonProps = {
    style: { width: "60%" },
  };

  return (
    <>
      <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800}>
        <Form form={form} layout="vertical">
          <AnimateItems delay={0.35}>
            <Form.Item
              name="TensionElectricaSuministro"
              label="Tensión de suministro"
              rules={[{ type: "number", message: "Ingrese un número" }]}
            >
              <InputNumber {...commonProps} addonAfter="V" />
            </Form.Item>
            <Form.Item name="CargaInstalada" label="Carga instalada" rules={[{ type: "number", message: "Ingrese un número" }]}>
              <InputNumber {...commonProps} addonAfter="kW" />
            </Form.Item>
            <Form.Item
              name="AlcanceVerificacion"
              label="Alcance de verificación"
              rules={[{ type: "number", message: "Ingrese un número" }]}
            >
              <InputNumber {...commonProps} addonAfter="kW" />
            </Form.Item>
            <Form.Item name="Capacidad" label="Capacidad de la subestación" rules={[{ type: "number", message: "Ingrese un número" }]}>
              <InputNumber {...commonProps} addonAfter="kVA" />
            </Form.Item>
          </AnimateItems>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            const verificationData = { ...verification, ...form.getFieldsValue() };
            form
              .validateFields()
              .then(() => {
                axios
                  .put(`${process.env.REACT_APP_API_URL}/verificaciones/${verification.VerificacionID}`, verificationData)
                  .then(() => {
                    message.success("Campos actualizados correctamente");
                    setVerification(verificationData);
                    setVisible(false);
                  })
                  .catch((err) => {
                    console.log(err); // error in request
                    message.error("Error al actualizar los campos");
                  });
              })
              .catch((err) => {
                console.log(err); // error in field validation
                message.error("Error al validar los campos");
              });
          }}
        >
          Guardar
        </Button>
      </Modal>
    </>
  );
}
