import { useState, useContext, createContext } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authed, setAuthed] = useState(false);
  const [nombre, setNombre] = useState("");
  const [rol, setRol] = useState("");
  const [UvieID, setUvieID] = useState("");

  const login = (user) => {
    setNombre(user.nombre + " " + user.apellido);
    setRol(user.rol);
    setUvieID(user.UvieID);
    setAuthed(true);
  };

  const logout = () => {
    setAuthed(false);
  };

  return <AuthContext.Provider value={{ authed, nombre, rol, UvieID, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
