import { Modal, Form, Input, Button, Space, message } from "antd";
import { useEffect } from "react";
import axios from "axios";

export default function ClienteForm(props) {
  const { visible, setVisible, setClientes, cliente } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (cliente !== null) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/clientes/${cliente}`)
        .then((res) => {
          form.setFieldsValue(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
          message.error("Error al cargar los datos del cliente");
        });
    } else {
      form.resetFields();
    }
  }, [cliente, form]);

  const submitNewCliente = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/clientes`, values)
          .then((res) => {
            message.success("Cliente creado");
            axios
              .get(`${process.env.REACT_APP_API_URL}/clientes`)
              .then((res) => {
                setClientes(res.data);
                message.success("Lista de clientes actualizada");
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al obtener los clientes");
              });
            setVisible(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Error al crear el cliente");
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al validar los campos");
      });
  };

  const submitEditedCliente = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .put(`${process.env.REACT_APP_API_URL}/clientes/${cliente}`, values)
          .then((res) => {
            message.success("Cliente actualizado");
            axios
              .get(`${process.env.REACT_APP_API_URL}/clientes`)
              .then((res) => {
                setClientes(res.data);
                message.success("Lista de clientes actualizada");
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al obtener los clientes");
              });
            setVisible(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Error al actualizar el cliente");
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al validar los campos");
      });
  };

  return (
    <>
      <Modal title="Cliente" visible={visible} onCancel={() => props.setVisible(false)} footer={null} centered>
        <Form form={form} layout="vertical">
          <Form.Item name="NombreCliente" label="Nombre" rules={[{ required: true, message: "Escriba el nombre del cliente" }]}>
            <Input placeholder="Nombre del cliente" />
          </Form.Item>
          <Form.Item name="ApodoAlias" label="Apodo">
            <Input placeholder="Apodo o alias del cliente" />
          </Form.Item>
          <Form.Item name="Telefono" label="Teléfono">
            <Input placeholder="Teléfono del cliente" />
          </Form.Item>
          <Form.Item name="CorreoElectronico" label="Correo electrónico">
            <Input placeholder="Correo electrónico del cliente" />
          </Form.Item>
        </Form>
        <Space direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              if (cliente !== null) {
                submitEditedCliente();
              } else {
                submitNewCliente();
              }
            }}
          >
            Guardar
          </Button>
          <Button onClick={() => setVisible(false)}>Cancelar</Button>
        </Space>
      </Modal>
    </>
  );
}
